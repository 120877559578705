import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { formatCnpj } from "../../utils/Tools";
import { useNavigate } from 'react-router-dom';
import { formatBankAccount, formatarTelefone } from "../../pages/Investidor";
import axios from "axios";
import { IoIosCloudUpload } from 'react-icons/io';
import { formatFileSize } from "../../utils/Tools";
import { navigate } from "react-router-dom";

Modal.setAppElement("#root"); // Define o elemento raiz da aplicação para o modal

const InvestorModal = ({ isOpen, onClose, investorData }) => {
    console.log(investorData,'investorData')
    const [razaoSocial, setRazaoSocial] = useState(investorData ? investorData.razaoSocial : "");
    const [razaoSocialUp, setRazaoSocialUp] = useState(investorData.razaoSocial);
    const [cnpj, setCnpj] = useState(investorData.cnpj);
    const [cep, setCep] = useState(investorData.cep);
    const [credorRua, setCredorRua] = useState(investorData.rua);
    const [credorNumero, setCredorNumero] = useState(investorData.numero);
    const [credorComplemento, setCredorComplemento] = useState(investorData.complemento);
    const [loading, setLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [credorBairro, setCredorBairro] = useState(investorData.bairro);
    const [credorCidade, setCredorCidade] = useState(investorData.cidade);
    const [credorEstado, setCredorEstado] = useState(investorData.estado);
    const [email, setEmail] = useState(investorData.email);
    const [telefone, setTelefone] = useState(investorData.telefone);
    const [selectedBank, setSelectedBank] = useState(investorData.selectedBank);
    const [bankNumber, setBankNumber] = useState(investorData.bankNumber);
    const [conta, setConta] = useState(investorData.conta);
    const [agency, setAgency] = useState(investorData.agencia);
    const [tipoDeInstituicao, setTipoDeInstituicao] = useState(investorData.tipoDeInstituicao);
    const [name, setName] = useState(investorData.nome);
    const [name2, setName2] = useState(investorData.nome2);
    const [dadosBancarios, setDadosBancarios] = useState('');
    const [banks, setBanks] = useState([]);
    //const [representantes, setRepresentantes] = useState([{ nome: '', email: '' }])
    const [representantes, setRepresentantes] = useState( investorData.representantes);
    const [realFaturamento, setRealFaturamento] = useState(investorData.realFaturamento);
    const [conSocial, setConSocial] = useState(investorData.conSocial);
    const [editedData, setEditedData] = useState({ ...investorData });
    const [realFaturamentoShow, setRealFaturamentoShow] = useState(false);
    const [conSocialShow, setConSocialShow] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        setEditedData({ ...investorData });
    }, [investorData]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, [dadosBancarios]);

    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const addRepresentante = () => {
        setRepresentantes([...representantes, { nome: '', email: '' }]); // Adiciona um novo representante vazio
    };

    const removeRepresentante = (index) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes.splice(index, 1);
        setRepresentantes(updatedRepresentantes);
    };
    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        } else {
            setBankNumber('');
        }
    };
    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });
    const handleRepresentanteChange = (index, field, value) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = value;
        setRepresentantes(updatedRepresentantes);
    };
    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i; // Retorna a posição da primeira correspondência encontrada
            }
        }
        return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
    }

  /*  const handleSave = () => {
        const editedData = {
            razaoSocial,
            cnpj,
            cep,
            rua: credorRua,
            numero: credorNumero,
            complemento: credorComplemento,
            bairro: credorBairro,
            cidade: credorCidade,
            estado: credorEstado,
            representantes,
            telefone,
            selectedBank,
            bankNumber,
            conta,
            agencia: agency,
            tipoDeInstituicao,

        };

        // Envia os dados atualizados para a API

        axios.put(`https://c2easybe-production.up.railway.app/investidor/${investorData._id}`, editedData)
            .then((response) => {
                window.alert('Dados atualizados com sucesso!');
                onClose();
            })
            .catch((error) => {
                // Lógica para lidar com erros na requisição
                console.error("Erro ao atualizar dados do investidor:", error);
            });
    };*/
    const handleSave = async (e) => {
        //alert('dsdsd')
        e.preventDefault();
        const editedData = {
            razaoSocial,
            cnpj,
            cep,
            rua: credorRua,
            numero: credorNumero,
            complemento: credorComplemento,
            bairro: credorBairro,
            cidade: credorCidade,
            estado: credorEstado,
            representantes,
            telefone,
            selectedBank,
            bankNumber,
            conta,
            agencia: agency,
            tipoDeInstituicao,

        };
        const formData = new FormData();
       formData.append('razaoSocial', razaoSocialUp);
       formData.append('cnpj', cnpj);
       formData.append('cep', cep);
       formData.append('rua', credorRua);
       formData.append('bairro', credorBairro);
       formData.append('numero', credorNumero);
       formData.append('complemento', credorComplemento);
       formData.append('cidade', credorCidade);
       formData.append('estado', credorEstado);
       // Adicione representantes (supondo que representantes é um array de objetos)
       representantes.forEach((representante, index) => {
           formData.append(`representantes[${index}][nome]`, representante.nome);
           formData.append(`representantes[${index}][email]`, representante.email);
       });

       formData.append('telefone', telefone);
       formData.append('banco', selectedBank);
       formData.append('numeroDoBanco', bankNumber);
       formData.append('conta', conta);
       formData.append('agencia', agency);
       // Adicione os arquivos
        formData.append('realFaturamentoFile', realFaturamento);
        formData.append('conSocialFile', conSocial);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/investidor/UpdateinvestidorNew/${investorData._id}`,{
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                window.alert('Dados atualizados com sucesso!');
                 navigate('/investidor')
            } else {
                  // Tratar erros
                 console.error('Erro ao enviar o formulário para o servidor');
            }
        } catch (error) {
            // Lógica para lidar com erros na requisição
            console.error("Erro ao atualizar dados do investidor:", error);
        }

    }
    const handleFileChange = (e, fileType) => {
        const selectedFiles = e.target.files;

        const file = selectedFiles[0];
        if( fileType === 'faturamento'){
            setRealFaturamentoShow(true)
            setRealFaturamento(file)
        }else{
            setConSocial(file)
            setConSocialShow(true)
        }
         
    };
    const handleRemoverArquivo = (fileType) => {
        if( fileType === 'faturamento'){
            setRealFaturamento(null)
            setRealFaturamentoShow(false)
        }else{
            setConSocial(null)
            setConSocialShow(false)
        }
       // fileType === 'faturamento' ? setRealFaturamento(null) : setConSocial(null);
       // setRealFaturamento(null); // Define realFaturamento como null para indicar que não há mais arquivo
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Investor Modal"
        >
            <div className="modalBody">
                <form className="formDoc1" encType="multipart/form-data" onSubmit={handleSave}>
                <   h2 className="h1Doc3">Detalhes do Investidor</h2>
                    <div className="divTitleDoc">
                        <h1 className="h1Doc2">Investidor</h1>
                    </div>
                    <div className={`divInputsDoc`}>
                        <label htmlFor="" className="labelInputsDoc">
                            Razão Social:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={razaoSocialUp}
                            onChange={(e) => { setRazaoSocialUp(e.target.value) }}
                            maxLength={120} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CNPJ:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={formatCnpj(cnpj)}
                            onChange={(e) => { setCnpj(e.target.value) }}
                            maxLength={18}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CEP:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={cep}
                            onChange={(e) => { setCep(e.target.value) }}
                            onBlur={handleSearch} />{loading && <p width="10%">Buscando...</p>}
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Rua:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorRua}
                            onChange={(e) => { setCredorRua(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Número:
                        </label>
                        <input
                            type="number"
                            className={`inputsDoc`}
                            value={credorNumero}
                            onChange={(e) => { setCredorNumero(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Complemento:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorComplemento}
                            onChange={(e) => { setCredorComplemento(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Bairro:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorBairro}
                            onChange={(e) => { setCredorBairro(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Cidade:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorCidade}
                            onChange={(e) => { setCredorCidade(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Estado:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={credorEstado}
                            onChange={(e) => { setCredorEstado(e.target.value) }}
                        />
                    </div>
                    <div className="divTitleDoc">
                        <h1 className="h1Doc2">Representantes</h1>
                    </div>
                    {representantes.map((representante, index) => (
                        <div key={index} className="divRepresentantes2">
                            <div className="rowRepre">
                                <label htmlFor="" className="labelInputsDoc">
                                    Representante {index + 1}:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDoc`}
                                    value={representante.nome}
                                    onChange={(e) => handleRepresentanteChange(index, 'nome', e.target.value)}
                                    maxLength={60}
                                />
                            </div>
                            <div className="rowRepre">
                                <label htmlFor="" className="labelInputsDoc3">
                                    E-mail:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsRep3`}
                                    value={representante.email}
                                    onChange={(e) => handleRepresentanteChange(index, 'email', e.target.value)}
                                />
                            </div>
                            {index >= 0 && ( // Impede a remoção do primeiro campo
                                <button type="button" className="botaoRemove"onClick={() => removeRepresentante(index)}>Remover</button>
                            )}
                        </div>
                    ))}
                    <button type="button" className="buttonDoc" onClick={addRepresentante}>+</button>

                    <div className="divTitleDoc">
                        <h1 className="h1Doc2" style={{marginBottom:'40px'}}>Documentos</h1>
                    </div>
                    <div className="divInputsDoc">
                            <label className="customFileInput3">
                                <input type="file" name="files" accept=".pdf, .doc, .docx" onChange={(e) => handleFileChange(e, 'faturamento')} style={{ display: "none" }}/>
                                <IoIosCloudUpload className="uploadIcon" />
                                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>Relatório de Faturamento</span>
                            </label>
                    </div>
                    <div className="divInputsDoc">
                            <label className="customFileInput3">
                                <input type="file" name="files" accept=".pdf, .doc, .docx" onChange={(e) => handleFileChange(e, 'contratos')} style={{ display: "none" }}/>
                                <IoIosCloudUpload className="uploadIcon" />
                                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>Contrato Social</span>
                            </label>
                    </div>
                    <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {realFaturamentoShow ? (
                                <div className="uploadedFileItem">
                                     <span>{'Faturamento'}</span>
                                    <span>{realFaturamento.name}</span>
                                    <span>{realFaturamento.size}</span>
                                    <button onClick={() => handleRemoverArquivo('faturamento')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                    </div>
                    <div className="uploadedFilesList" style={{ marginTop: "15px" }}>
                            {conSocialShow ? (
                                <div className="uploadedFileItem">
                                     <span>{'Contrato'}</span>
                                    <span>{conSocial.name}</span>
                                    <span>{formatFileSize(conSocial.size)}</span>
                                    <button onClick={() => handleRemoverArquivo('social')} className="button11">Remover</button>
                                </div>
                            ) : (
                                <div>
                                   {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                </div>
                            )}
                    </div>
                    <div className="divTitleDoc">
                        <h1 className="h1Doc2" style={{marginBottom:'40px'}}>Informações Complementares</h1>
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Telefone:
                        </label>
                        <input
                            type="text"
                            className={`inputsDoc`}
                            value={formatarTelefone(telefone)}
                            onChange={(e) => { setTelefone(e.target.value) }}
                            maxLength={15} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Dados Bancários
                        </label>
                        <select value={selectedBank} onChange={handleBankChange} className="selectDoc">
                            <option value="" className="optionInputsDoc">Selecione um banco</option>
                            <option value="BNK Digital" className="optionInputsDoc">BNK Digital</option>
                            {sortedBanks.map((bank, index) => (
                                <option key={index} value={bank.name} className="optionInputsDoc">
                                    {bank.name}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">Número do Banco</label>
                        <input
                            type='text'
                            name="Número do banco"
                            placeholder="Selecione um banco"
                            className={`inputsDoc`}
                            value={bankNumber}
                            onChange={(e) => { setBankNumber(e.target.value) }}
                            maxLength={20}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Agência:
                        </label>
                        <input
                            placeholder="12345"
                            type="text"
                            className={`inputsDoc`}
                            value={agency}
                            onChange={(e) => { setAgency(e.target.value) }}
                            maxLength={8} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">Conta</label>
                        <input
                            placeholder="123 456-X"
                            type="text"
                            className={`inputsDoc`}
                            value={formatBankAccount(conta)}
                            onChange={(e) => { setConta(e.target.value) }}
                            maxLength={13} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Tipo de Instituição:
                        </label>
                        <select
                            className="selectDoc"
                            value={tipoDeInstituicao}
                            onChange={(e) => setTipoDeInstituicao(e.target.value)}
                        >
                            <option value="FIDC">FIDC</option>
                            <option value="Factoring">Factoring</option>
                            <option value="Securitizadora">Securitizadora</option>
                        </select>
                    </div>
                    <div style={{ display: 'flex' }}>

                        {/*<button className="button" onClick={handleSave}>Salvar</button>*/}
                        <button className="button">Salvar</button>
                        <button className="button" onClick={onClose}>Cancelar</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default InvestorModal;
