import React, { useState } from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import '../styles/Investidors.css';
import {  IoIosDownload } from 'react-icons/io';

function InvestidorModal({ selectedEmissor, handleSave, onClose }) {
    let isLinkPresente = '';
    let isLinkPresente2 = '';
   // console.log(selectedEmissor,'selectedEmissor')
    if (selectedEmissor && selectedEmissor.realFaturamento) {
       isLinkPresente = selectedEmissor.realFaturamento !== undefined;
   }
   if (selectedEmissor && selectedEmissor.conSocial) {
     isLinkPresente2 = selectedEmissor.realFaturamento !== undefined;
    }
  // const isLinkPresente = selectedEmissor && selectedEmissor.realFaturamento && selectedEmissor.realFaturamento !== undefined;
    return (

        <div>
            <div className="modal">
                <div className="modal-content">
                    <button onClick={onClose} className="modalClose">X</button>
                    <h3 className="per1">Detalhes do Investidor Selecionado:</h3>

                    <div className="investidorContainer">
                        <div className="investidorField">
                            <div className="invesP">Origem:</div>
                            <div className="invesP3">{selectedEmissor?.origemRazaoSocial || "-"}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Razão Social:</div>
                            <div className="invesP3">{selectedEmissor?.razaoSocial}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">CNPJ:</div>
                            <div className="invesP3">{selectedEmissor?.cnpj}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Representante:</div>
                            <div className="invesP3">{selectedEmissor?.representantes[0].nome}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Endereço:</div>
                            <div className="invesP3">
                                CEP: {selectedEmissor?.cep},<br />
                                Rua: {selectedEmissor?.rua},<br />
                                Bairro: {selectedEmissor?.bairro},<br />
                                Cidade: {selectedEmissor?.cidade},<br />
                                UF: {selectedEmissor?.estado}
                            </div>
                        </div>
                        <div className="investidorField">
                            <a  href={isLinkPresente ? selectedEmissor.realFaturamento : '#'} className={`customFileInput2 ${isLinkPresente ? '' : 'opacoButton'}`} download={isLinkPresente ? 'NomeDoArquivoParaDownload' : ''}>
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Relatório de Faturamento`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={isLinkPresente2 ? selectedEmissor.conSocial : '#'} className={`customFileInput2 ${isLinkPresente2 ? '' : 'opacoButton'}`} >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Contrato Social`} </span>
                            </a>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                        <button onClick={() => { handleSave(selectedEmissor) }} className="newButtonInves">Selecionar Investidor para Nota Comercial</button>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default InvestidorModal;
