import { useEffect, useRef, React, useState } from 'react';
import '../styles/Home.css'
import { FaCalculator, FaRightFromBracket } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDados } from '../redux/actions';
import { useSelector } from 'react-redux';
import { useCalculoTabela } from '../utils/Generator';
import { useCalculoSac } from '../utils/Sac';
import { NumericFormat } from 'react-number-format';
import Header from '../components/Header';
import Inicio from './Inicio';
import { PiArrowSquareUp } from 'react-icons/pi';
import Switch from 'react-switch';
import { formatCurrency } from '../utils/Tools';


const Home = () => {
    const storedData = JSON.parse(localStorage.getItem('formValues'));
    localStorage.setItem('garantia', false);
    const today = new Date();
    const todayYMD = today.toISOString().slice(0, 10);
    const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate() - 1).toISOString().slice(0, 10); // Data máxima um mês a partir da data atual
    const user = JSON.parse(localStorage.getItem('user'));
    const [valorTotal, setValorTotal] = useState(storedData ? storedData.valorTotal : '0');
    const [parcelas, setParcelas] = useState(storedData ? storedData.parcelas : '');
    const [dataEmissao, setDataEmissao] = useState(storedData ? storedData.dataEmissao : todayYMD);
    const [vencimento, setVencimento] = useState(storedData ? storedData.vencimento : maxDate);
    const [carencia, setCarencia] = useState(storedData ? storedData.carencia : '0');
    const [juros, setJuros] = useState(storedData ? storedData.juros : '0');
    const [jurosAA, setJurosAA] = useState(storedData ? storedData.jurosAA : '0');
    const [capital, setCapital] = useState(storedData ? storedData.capital : '0');
    const [taxaEmissao, setTaxaEmissao] = useState(storedData ? storedData.taxaEmissao : '');
    const [emissaoPorcento, setEmissaoPorcento] = useState(storedData ? storedData.emissaoPorcento : '0.5');
    const [emissaoMoeda, setEmissaoMoeda] = useState(storedData ? storedData.emissaoPorcento : '0');
    const [emissaoCCB, setEmissaoCCB] = useState(storedData ? storedData.emissaoCCB : '0');
    const [tac, setTac] = useState(storedData ? storedData.tac : '0');
    const [alienacao, setAlienacao] = useState(storedData ? storedData.alienacao : '0');
    const [errorMessage, setErrorMessage] = useState('');
    const [dias, setDias] = useState(storedData ? storedData.dias : 30);
    const [modeloCalculo, setModeloCalculo] = useState(storedData ? storedData.modeloCalculo : 'Irregular');
    const [assinaturaDigital, setAssinaturaDigital] = useState(storedData ? storedData.assinaturaDigital : 20);
    const [tipocarencia, setTipoCarencia] = useState( storedData ? storedData.tipocarencia: 1); // valor inicial 1, pode ser ajustado conforme necessário
    const showError = (message) => {
        setErrorMessage(message);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dados = useSelector((state) => state.dados.dados);
    const { calcularTabela } = useCalculoTabela();
    const { calcularSac } = useCalculoSac();
    const valorTotalInput = useRef(null);
    const parcelasInput = useRef(null);
    const jurosInput = useRef(null);
    const jurosAAInput = useRef(null);
    const carenciaInput = useRef(null);
    const capitalInput = useRef(null);
    const tacInput = useRef(null);
    const alienacaoInput = useRef(null);
    const taxaEmissaoInput = useRef(null);
    const emissaoCCBInput = useRef(null);
    const assinaturaDigitalInput = useRef(null);
    const tradutordeModeloCalculo = ( modeloCalculo = "Irregular") => {
        const tabela = {
            'Regular': 'Cálculo Padrão Juros 30 Dias',
            'Irregular': 'Cálculo de Juros Período Exato',
          };
          return (tabela[modeloCalculo] || tabela['Irregular']);
    }
    const validateInputs = () => {
        
        if (valorTotal<=0 || isNaN(parseFloat(valorTotal))) {
            valorTotalInput.current.focus();
            showError('Por favor, insira um valor válido para Valor da Operação.');
            return false;
        }

        if (!parcelas || isNaN(parseInt(parcelas, 10)) || parcelas <= 0) {
            parcelasInput.current.focus();
            showError('Por favor, insira um número válido de Parcelas.');
            return false;
        }

        const dataEmissaoToValid = new Date(dataEmissao), vencimentoToValid = new Date(vencimento);
        if (!dataEmissao) {
            showError('Por favor, selecione uma data de Emissão para a nota.');
            return false;
        }

        if (!vencimento) {
            showError('Por favor, selecione uma data de Vencimento da Primeira Parcela.');
            return false;
        }

        if(dataEmissaoToValid>vencimentoToValid){
            showError('Por favor, insira uma data de Emissão menor que a data de Vencimento da Primeira Parcela.');
            return false;
        }

        if(vencimentoToValid<dataEmissaoToValid){
            showError('Por favor, insira uma data de Vencimento da Primeira Parcela maior que a data de Emissão');
            return false;
        }

        if (carencia >= parcelas) {
            showError('Por favor, insira um número de Carencia menor que o número de Parcelas.');
            setCarencia(0);
            return false;
        }
    
        if (carencia === null || isNaN(parseInt(carencia, 10)) || carencia >= parcelas) {
            carenciaInput.current.focus();
            showError('Por favor, insira um número válido para Carência.');
            return false;
        }

        if (juros <= 0 || isNaN(parseInt(juros))) {
            jurosInput.current.focus();
            showError('Por favor, insira um número válido Taxa de Juros (a.m).');
            return false;
        }

        if (jurosAA <= 0 || isNaN(parseInt(jurosAA))) {
            jurosAAInput.current.focus();
            showError('Por favor, insira um número válido Taxa de Juros (a.a).');
            return false;
        }

        if (capital<0 || isNaN(parseFloat(capital))) {
            capitalInput.current.focus();
            showError('Por favor, insira um valor válido para Custo de Capital.');
            return false;
        }

        if (emissaoPorcento<=0 || isNaN(parseFloat(emissaoPorcento))) {
            taxaEmissaoInput.current.focus();
            showError('Por favor, insira uma taxa válida para Taxa de Emissão, maior que 0%.');
            return false;
        }

        if (tac<0||isNaN(parseFloat(tac))) {
            tacInput.current.focus();
            showError('Por favor, insira um valor válido para TAC.');
            return false;
        }

        if (!alienacao || isNaN(parseFloat(alienacao))) {
            alienacaoInput.current.focus();
            showError('Por favor, insira um valor válido para Despesa com Alienação.');
            return false;
        }

        if (!emissaoCCB || isNaN(parseFloat(emissaoCCB))) {
            emissaoCCBInput.current.focus();
            showError('Por favor, insira uma taxa válida para Taxa de Emissão CCB.');
            return false;
        }

        return true; // Todos os campos estão válidos
    };

    useEffect(() => {
        // Função que será chamada quando o valorTotal for alterado
        const handleValorTotalChange = () => {
            handleTaxaEmissao(emissaoPorcento);
        };

        // Adiciona um observador para o valorTotal
        const valorTotalObserver = setInterval(handleValorTotalChange, 500); // Altere o intervalo conforme necessário

        // Limpa o observador quando o componente é desmontado
        return () => clearInterval(valorTotalObserver);
    }, [valorTotal, emissaoPorcento]);

    const handleCall = (call) => {
        const inputsValid = validateInputs();
        handleTaxaEmissao(emissaoPorcento);
        if (!inputsValid) {
            return;
        }
        if (call === 'sac') {
            const vencimentoObj = new Date(vencimento);
            const dia = vencimentoObj.getDate();
            const mes = vencimentoObj.getMonth();
            const ano = vencimentoObj.getFullYear();

            calcularSac(valorTotal, juros, parcelas, carencia, capital, taxaEmissao, dia, mes, ano, emissaoCCB, tac, alienacao, tipocarencia, assinaturaDigital);
            navigate('/result')
            return
        }
        if (call === 'price') {
            const vencimentoObj = new Date(vencimento);
            const dia = vencimentoObj.getDate();
            const mes = vencimentoObj.getMonth();
            const ano = vencimentoObj.getFullYear();
            calcularTabela(assinaturaDigital, modeloCalculo, dias, valorTotal, juros, parcelas, carencia, capital, taxaEmissao, dia, mes, ano, emissaoCCB, tac, alienacao, tipocarencia, dataEmissao)
            navigate('/result')
            return
        }
    };
    const handleChange = () => {
        const newValue = parseInt(valorTotal) + parseInt(tac) + parseInt(alienacao);
        const numParcelas = parcelas;

    };
    const handleDias = (checked) => {
        setDias(checked ? 30 : 21);
    }
    useEffect(() => {
        handleChange();
    }, [valorTotal, parcelas, tac, alienacao]);

    const calcularJurosAA = (jurosMensal) => {
        const jurosAnual = (Math.pow(1 + jurosMensal / 100, 12) - 1) * 100;
        return jurosAnual.toFixed(2);
    };

    const calcularJurosMensal = (jurosAnual) => {
        const jurosMensal = (Math.pow(1 + jurosAnual / 100, 1 / 12) - 1) * 100;
        return jurosMensal.toFixed(2);
    };


    // Armazenar os dados no local storage sempre que houver uma mudança em qualquer input
    useEffect(() => {
        const formValues = {
            assinaturaDigital,
            valorTotal,
            parcelas,
            vencimento,
            carencia,
            juros,
            jurosAA,
            capital,
            taxaEmissao,
            emissaoCCB,
            tac,
            alienacao,
            emissaoPorcento,
            dias,
            modeloCalculo,
            tipocarencia,
            dataEmissao
        };
        localStorage.setItem('formValues', JSON.stringify(formValues));
    }, [assinaturaDigital, valorTotal, parcelas, vencimento, carencia, juros, jurosAA, capital, taxaEmissao, emissaoCCB, tac, alienacao, emissaoPorcento, dias, modeloCalculo, tipocarencia, dataEmissao]);
    const userTokenString = localStorage.getItem('user');
    let userToken1 = '';
    let userToken = '';
    const primeiroDiaProximoMes = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const handleModelo = () => {
        modeloCalculo === 'Regular' ? setModeloCalculo('Irregular') : setModeloCalculo('Regular');

    }
    const minDate = primeiroDiaProximoMes.toISOString().split('T')[0];
    if (userTokenString) {
        userToken1 = JSON.parse(userTokenString);
        userToken = userToken1.data
    } else {
        console.log('Usuário não encontrado no localStorage.');
    }
    let userName = userToken.nome ?? 'teste';
    const valorBaseTaxaEmissao = () =>{
        return parseInt(valorTotal) +
        parseInt(tac) +
        parseInt(alienacao);
    }

    const limitadorDeTaxaEmissao  = (taxaProntaX) =>{
        return (taxaProntaX<=1500.00) ? 1500.00: taxaProntaX;
    }
    const handleTaxaEmissao = (taxaPorcento) => {
        try {

            if(taxaPorcento == '') taxaPorcento  = '0';
            setEmissaoPorcento(taxaPorcento);

            const newValue = taxaPorcento;

            const newBase = valorBaseTaxaEmissao();

            let taxaPronta = newBase * (newValue / 100);

            taxaPronta = limitadorDeTaxaEmissao(taxaPronta);

            setTaxaEmissao(taxaPronta.toFixed(2));
        } catch (error) {
            console.error(error);
        }
    };
    const handleTaxaEmissaoMoeda = (emissaoMoeda) => {
        try {
            if(emissaoMoeda == '') emissaoMoeda = '0';
            emissaoMoeda = parseFloat(emissaoMoeda);

            setEmissaoMoeda(emissaoMoeda.toFixed(2));

            const newValue = limitadorDeTaxaEmissao(emissaoMoeda);

            const newBase = valorBaseTaxaEmissao();

            let taxaPorcento = newValue / newBase;
            setEmissaoPorcento(taxaPorcento.toFixed(8));

        } catch (error) {
            console.error(error);
        }
    }
    const handleDataChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const today = new Date();

        // Verificar se a data selecionada é hoje ou anterior
        if (selectedDate <= today) {
            alert('Selecione uma data futura.');
            // Você também pode definir um estado para exibir uma mensagem de erro, se preferir.
        } else {
            setVencimento(e.target.value);
        }
    };
    const handleDataEmissaoChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const today = new Date();
        setDataEmissao(e.target.value);
    };

    return (
        <body className="homeBody">
            <div className="fixed">
                <Header selected='inicio' />
            </div>
            <main className="homeMain">
                <div className="ahead">
                    <div className="div1">
                        <h2>Simular Cálculo</h2>
                    </div>

                    <div className="div1">
                        <h3>Bem Vindo, {user.data.razaoSocial}</h3>
                    </div>
                </div>
                <form className="formHome"  onSubmit={(e)=> e.preventDefault()}>
                    <h2 className="formH2">Simular Nota Comercial</h2>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        marginLeft: '6vh',
                        marginBottom: '1vh',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '49%',
                    }}>
                        <label >Base de {dias === 30 ? '360' : '252'} dias</label>
                        <Switch
                            onChange={handleDias}
                            checked={dias === 30}
                        />

                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        marginBottom: '1vh',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '40%',
                    }}>
                        <label >Modelo de cálculo: { tradutordeModeloCalculo(modeloCalculo) }</label>
                        <Switch
                            onChange={handleModelo}
                            checked={modeloCalculo === 'Regular'}
                        />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="valorOperacao">Valor da Operação:</label>
                        <NumericFormat
                            className='inputHome'
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'R$ '}
                            allowNegative={false}
                            placeholder={formatCurrency(valorTotal)}
                            onValueChange={(values) => {
                                if(values.value == '') values.value = '0';
                                setValorTotal(values.value);
                            }}
                            getInputRef={valorTotalInput}
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome2" htmlFor="quantidadeParcelas">Quantidade de Parcelas:</label>
                        <input
                            placeholder="Selecione"
                            className="inputHome2"
                            type="number"
                            name="quantidadeParcelas"
                            id="quantidadeParcelas"
                            value={parcelas}
                            min={1}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (!isNaN(newValue) && newValue >= 0) {
                                    setParcelas(newValue);
                                } else {
                                    setParcelas('');
                                }
                            }}
                            ref={parcelasInput}
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="dataEmissao">Data Emissão:</label>
                        <input
                            className="inputHome2"
                            type="date"
                            name="dataEmissao"
                            value={dataEmissao}
                            onChange={handleDataEmissaoChange}
                            id="dataEmissao"
                            required
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="vencimentoParcela">Vencimento da Primeira Parcela:</label>
                        <input
                            className="inputHome2"
                            type="date"
                            name="vencimentoParcela"
                            value={vencimento}
                            onChange={handleDataChange}
                            id="vencimentoParcela"
                            required
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="tipocarencia">Tipo de Carência:</label>
                        <select
                            className="inputHome2"
                            name="tipocarencia"
                            id="tipocarencia"
                            value={tipocarencia}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                setTipoCarencia(newValue);
                            }}
                            required
                        >   
                            <option  value={1}>Carência na Amortização</option>
                            <option  value={2}>Carência Total</option>
                        </select>
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="carencia">Carência:</label>
                        <input
                            placeholder="Selecione"
                            className="inputHome2"
                            type="number"
                            name="carencia"
                            id="carencia"
                            value={carencia}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (!isNaN(newValue) && newValue >= 0 && newValue < parcelas) {
                                    setCarencia(newValue);
                                }
                            }}
                            ref={carenciaInput }
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="taxaJuros">Taxa de Juros (a.m):</label>
                        <NumericFormat
                            className='inputHome2'
                            value={''}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={6}
                            fixedDecimalScale={true}
                            suffix={'%'}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={`${(juros)? juros.replace('.', ','):'0' }%`}
                            onValueChange={(values) => {
                                if(values.value == '') values.value = '0';
                                if(values.floatValue == undefined) values.floatValue = 0;
                                setJuros(values.value)
                                setJurosAA(calcularJurosAA(values.floatValue));
                            }}
                            getInputRef={jurosInput}
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="taxaJuros">Taxa de Juros (a.a):</label>
                        <NumericFormat
                            className='inputHome2'
                            value={''}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix={'%'}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={`${(jurosAA)? jurosAA.replace('.', ','):'0' }%`}
                            onValueChange={(values) => {
                                if(values.value == '') values.value = '0';
                                if(values.floatValue == undefined) values.floatValue = 0;
                                setJuros(calcularJurosMensal(values.floatValue));
                                setJurosAA(values.value);
                            }}
                            getInputRef={jurosAAInput}
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                    <label className="labelHome" htmlFor="taxaEmissao">TAC:</label>
                        <NumericFormat
                            className='inputHome2'
                            value={''}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'R$ '}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={`${formatCurrency(tac)}`}
                            onValueChange={(values) => {
                                setTac(values.value)
                            }}
                            getInputRef={tacInput}
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="taxaEmissao">Despesa com Alienação:</label>
                        <NumericFormat
                            className='inputHome2'
                            value={''}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'R$ '}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder= {`${formatCurrency(alienacao)}`}
                            onValueChange={(values) => {
                                setAlienacao(values.value)
                            }}
                            getInputRef={alienacaoInput}
                        />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="taxaEmissao">Custo da Assinatura Digital:</label>
                        <NumericFormat
                            className='inputHome2'
                            value={''}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'R$ '}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={`${formatCurrency(assinaturaDigital)}`}
                            onValueChange={(values) => {
                                setAssinaturaDigital(values.value)
                                
                            }}
                            getInputRef={taxaEmissaoInput}
                        />
                        <br/>
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="custoCapital">Custo de Capital:</label>
                        <NumericFormat
                            className='inputHome2'
                            value={''}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix={'%'}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={`${(capital)? capital.replace('.', ','):'0' }%`}
                            onValueChange={(values) => {
                                setCapital(values.value)
                            }}
                            getInputRef={capitalInput}
                        />
                        <br />
                    </div>
                        <div className="divInputsHome">
                                <label className="labelHome" htmlFor="taxaEmissao">Taxa de Emissão - Nota Comercial:</label>
                                <NumericFormat
                                    className='inputHome2'
                                    value={''}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={4}
                                    suffix='%'
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder={`${(emissaoPorcento)? emissaoPorcento.replace('.', ','):'0' }%`}
                                    onValueChange={(values) => {
                                        if(values.value == '') values.value = '0';
                                        if(values.floatValue == undefined) values.floatValue = 0;
                                        handleTaxaEmissao(values.value)
                                    }}
                                    getInputRef={taxaEmissaoInput}
                                />
                            <br />
                        </div>
                        <div className="divInputsHome">
                            <label className="labelHome" htmlFor="taxaEmissao">Taxa de Emissão - Nota Comercial($):</label>
                            <NumericFormat
                                className='inputHome2'
                                value={''}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                decimalScale={2}
                                prefix='R$ '
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isNumericString={true}
                                placeholder={`${formatCurrency(taxaEmissao)} `}
                                onValueChange={(values) => {
                                    if(values.value == '') values.value = '0';
                                    if(values.floatValue == undefined) values.floatValue = 0;
                                    handleTaxaEmissaoMoeda(values.value)
                                }}
                                />
                        <br />
                    </div>
                    <div className="divInputsHome">
                        <label className="labelHome" htmlFor="taxaJuros">Taxa de Emissão CCB:</label>
                        <NumericFormat
                            className='inputHome2'
                            value={''}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix={'%'}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={`${(emissaoCCB)? emissaoCCB.replace('.', ','):'0' }%`}
                            onValueChange={(values) => {
                                setEmissaoCCB(values.value)
                            }}
                            getInputRef={emissaoCCBInput}
                        />
                        <br />
                    </div>
                    {errorMessage && <div className="error-message" style={{ width: '98%', }}>{errorMessage}</div>}
                    <div className='divInputsHomeBreak'></div>
                    <div className="divInputsHome1" >
                        <button
                            style={{ marginLeft: '1%' }}
                            className="botaoHome"
                            onClick={() => handleCall('price')}
                        >Calcular Tabela Price
                            <FaCalculator size={20} className="iconCalc" />
                        </button></div>
                    <div className="divInputsHome1">

                        <button
                            style={{ marginLeft: '1%' }}
                            className="botaoHome"
                            onClick={() => handleCall('sac')}
                        >Calcular Tabela SAC
                            <FaCalculator size={20} className="iconCalc" />
                        </button>
                    </div>

                </form>

            </main>
        </body>
    );
};

export default Home;