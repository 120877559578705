import React, { useEffect } from "react";
import { Page, Text, View, Document, Font, Image, PDFViewer } from "@react-pdf/renderer";
//import { useSelector } from 'react-redux';
import { pdfStyle } from "./pdfStyles";
//import { useNavigate } from "react-router-dom";
import { formatDateString } from "./TrusteeSemGarantia";
import  HyphenationCallback  from "./PdfHyphenation";
import { estadoPorExtenso } from "../Tools";
Font.registerHyphenationCallback(HyphenationCallback);

const formatDate = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = months[Number(dateParts[1]) - 1];
    const day = Number(dateParts[2]);

    return `${day} de ${month} de ${year}`;
};

export const nascimentoAvalista = (text) => {
    if (text) {
        const meses = [
            'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
            'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
        ];

        const year = text.substring(0, 4);
        const month = parseInt(text.substring(5, 7)) - 1; // os meses começam de 0 (janeiro) a 11 (dezembro)
        const day = text.substring(8, 10);

        return `${day} de ${meses[month]} de ${year}`;
    }
}


const formatCnpj = (text) => {
    if (text) {
        const cnpj = text.replace(/\D/g, '');
        let formattedCnpj = '';

        for (let i = 0; i < cnpj.length; i++) {
            if (i === 2 || i === 5) {
                formattedCnpj += '.';
            } else if (i === 8) {
                formattedCnpj += '/';
            } else if (i === 12) {
                formattedCnpj += '-';
            }
            formattedCnpj += cnpj[i];
        }

        return formattedCnpj;
    }
};

const SingulareSemGarantia = ({ dados ,calcDataState,allInfoArrayState}) => {
   
    console.log(allInfoArrayState,'allInfoArrayState')
    const numero = require('numero-por-extenso');
    const calcData2 = calcDataState
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    const allInfoArray = allInfoArrayState;
    const calcData1 = JSON.parse(localStorage.getItem('calcData'))
    const calcData = calcData2 ? calcData2 : calcData1
  

    const valorTotal = allInfoArray[13]
    const now = new Date();
    const parcelas = allInfoArray[4]
    const dias = parcelas * 30;
    const currentZoom = window.devicePixelRatio;
    console.log(currentZoom);

    // Calcula o tamanho do componente PDFViewer com base no zoom
    const pdfViewerWidth = 2500 / currentZoom;
    const pdfViewerHeight = 1200 / currentZoom;

    const linhaDoVencimento = calcData[calcData.length - 1]
    const linhaPrimeira = calcData[0]
    const dataPrimeira = linhaPrimeira[1] < 10 ? `0${linhaPrimeira[1]}/${linhaPrimeira[2]}/${linhaPrimeira[3]}` : `${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}`;
    const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    const base = formValues.dias === 30 ? 360 : 252;
    const dataHoje = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
    const styles = pdfStyle
    const avalistas = emissor.avalistas || [];
    console.log(avalistas, 'avalistas');
    return (
        
        <PDFViewer width={'90%'} height={'85%'}>
            <Document>
                <Page style={styles.page}>
                    <View style={styles.textHeader}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                        <Text style={[styles.textHeader]}>TERMO CONSTITUTIVO DE NOTA COMERCIAL DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA COLOCAÇÃO
                            PRIVADA, {emissor.razaoSocial}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.h1}>I. EMISSORA</Text>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={styles.cellRight}><Text style={styles.headerCell}>Razão Social</Text> {'\n'}{emissor.razaoSocial}</Text>
                                <Text style={styles.cell}><Text style={styles.headerCell}>CNPJ/MF</Text>{'\n'}{emissor.cnpj}</Text>
                            </View>
                        </View>
                        <Text style={styles.text3}>
                            A Emissão e a celebração deste Termo Constitutivo de Nota Comercial da {dados.emissao} Emissão de Notas
                            Comerciais, em Série Única, para Colocação Privada, da {emissor.razaoSocial} (“Termo Constitutivo”) são
                            realizadas com base nas deliberações dos administradores da Emissora, conforme previsto no Parágrafo
                            único, artigo 46 da Lei nº14.195, de 26 de agosto de 2021 (“Lei 14.195”) e no contrato social da Emissora.
                        </Text>
                        <Text style={styles.h1}>II. RESUMO DA EMISSÃO</Text>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Data de Vencimento</Text>{'\n'}
                                    {dataDoVencimento}</Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Valor Total
                                    da Emissão</Text> {valorTotal}</Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Valor Nominal Unitário</Text>
                                    {valorTotal}</Text>
                                <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Quantidade</Text>{'\n'}
                                    1</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell5, { textAlign: 'start', width: '100%' }]}><Text style={styles.textBold}>Juros Remuneratórios</Text>{'\n'}
                                    {allInfoArray[10]}% a.m., base {base} ({numero.porExtenso(base, numero.estilo.normal)}) dias.</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Local de Pagamento</Text>{'\n'}
                                    São Paulo – SP, em conta
                                    corrente do Titular</Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Atualização
                                    Monetária</Text>{'\n'}As Notas
                                    Comerciais não
                                    serão atualizadas
                                    monetariamente.</Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Juros Remuneratórios</Text>{'\n'}
                                    {allInfoArray[10]}% a.m. base 360 (trezentos e sessenta) dias.

                                </Text>
                                <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Datas de Pagamento dos
                                    Juros Remuneratórios</Text>{'\n'}<Text style={styles.textBold}>      </Text>Conforme Fluxo de
                                    pagamento do Anexo 2</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cellRight}><Text style={styles.textBold}>Data de Pagamento do
                                    Valor Nominal Unitário</Text>{'\n'}
                                    Na data de vencimento.</Text>
                                <Text style={styles.cellRight}><Text style={styles.textBold}>Resgate
                                    Antecipado
                                    Facultativo Total e
                                    Oferta de Resgate
                                    Antecipado</Text>{'\n'}Mediante o
                                    pagamento dos
                                    juros incorridos no
                                    respectivo
                                    periodo.</Text>
                                <Text style={styles.cellRight}><Text style={styles.textBold}>Amortização Extraordinária</Text>{'\n'}
                                    Haverá possibilidade de
                                    amortização extraordinária,
                                    seja total ou parcial, das
                                    Notas Comerciais, desde que
                                    amortizados também os juros
                                    incorridos no respectivo
                                    período.</Text>
                                <Text style={[styles.cellRight, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Resgate Antecipado
                                    Compulsório Total</Text>{'\n'}As Notas Comerciais
                                    deverão ser resgatadas
                                    integralmente caso
                                    seja decretado o
                                    Vencimento
                                    Antecipado das Notas
                                    Comerciais, conforme
                                    abaixo definido.</Text>
                            </View>
                        </View>
                        <Text style={styles.h1}>III. PARTICIPANTES</Text>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={styles.cellRight}><Text style={styles.textBold}>Escriturador</Text> {'\n'}Singulare Corretora de Titulos e Valores Mobiliarios S.A.</Text>
                                <Text style={styles.cell}><Text style={styles.textBold}>CNPJ/MF</Text>
                                    {'\n'}62.285.390/0001-40
                                </Text>
                            </View>
                        </View>
                        <Text style={styles.h1}>1. OBJETO</Text>
                        <View>
                            <Text style={styles.text1}>O presente Termo Constitutivo tem por objeto a {dados.emissao} emissão de notas comerciais, não conversíveis, em série única, para colocação privada, da Emissora (“Emissão” e “Notas Comerciais”, respectivamente), representativas de promessa de pagamento em dinheiro, de acordo com as características, termos e condições estabelecidos neste Termo Constitutivo, nos termos da Lei nº 6.385, de 7 de dezembro de 1976, conforme alterada e da Lei 14.195.</Text>
                        </View>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.textHeader}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />

                    </View>
                    <View style={styles.section}>
                        <Text style={styles.h1}>2. CONDIÇÕES PRECEDENTES</Text>
                        <Text style={styles.text1}>A Emissão, está condicionada, nos termos do artigo 125 da Lei 10.406, de 10 de janeiro de 2002, conforme
                            alterada, à verificação do cumprimento dos seguintes atos (“Condições Precedentes”):
                        </Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}<Text style={styles.textBold}>a.       </Text>  perfeita formalização, pela Emissora e demais partes signatárias, do Termo Constitutivo e do Boletim
                            de Subscrição (conjuntamente, os “Documentos da Operação”), bem como a verificação dos poderes dos
                            representantes dessas partes e eventuais aprovações societárias necessárias à celebração desses documentos;
                            {'\n'}{'  '}<Text style={styles.textBold}>b.       </Text> recebimento, pela Emissora, dos Boletins de Subscrição devidamente formalizados, cujo modelo segue
                            anexo a este Termo Constituivo no Anexo I.</Text>

                        <Text style={styles.h1}>3 GARANTIAS</Text>

                        <Text style={styles.text1}>Garantia Fidejussória</Text>

                        {avalistas.map((avalista, index) => (
                            <Text key={index} style={styles.text1}>{'\n'}Aval.
                                Comparece {avalista.aNome && ` ${avalista.aNome},`} brasileiro, {avalista.aEstadoCivil && `${avalista.aEstadoCivil},`} {avalista.aProfissao && `${avalista.aProfissao},`} nascido em {avalista.aDataNascimento && `${nascimentoAvalista(avalista.aDataNascimento)},`} inscrito no CPF nº: {avalista.aCpf && ` ${avalista.aCpf}`} portador da Carteira Identidade n.º {avalista.aRg && ` ${avalista.aRg}`} {avalista.aOrgaoEmissor && ` ${avalista.aOrgaoEmissor}`}residente e domiciliado na {avalista.aRua && ` ${avalista.aRua},`} {avalista.aNumero && ` ${avalista.aNumero},`} {avalista.aBairro && ` ${avalista.aBairro} –`} {avalista.aEstado && ` ${avalista.aEstado},`} CEP {avalista.aCep && ` ${avalista.aCep},`} (“Avalista”) no presente Termo Constitutivo, em caráter irrevogável e irretratável, na condição de avalista, principais pagadores e responsáveis solidários com relação às Obrigações Garantidas.
                            </Text>
                        ))}

                        <Text style={styles.text1}>
                            {'\n'}
                            O Avalista, na condição de devedor solidário e principal pagador, juntamente com a Emissora, perante o Titular, para o adimplemento das Obrigações Garantidas, assinam o presente Termo Constitutivo e declaram estarem cientes da presente garantia, aceitando todos os termos, condições e responsabilidades que daí advenham, sem a existência de qualquer benefício de ordem entre a Emissora e o Avalista.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            O presente Aval entrará em vigor na Data de Emissão e permanecerá válido enquanto existirem Obrigações Garantidas, extinguindo-se imediata e automaticamente mediante seu integral cumprimento.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            O Aval aqui previsto considera-se prestado a título oneroso, de forma que o Avalista possui interesse econômico no resultado da operação, beneficiando-se indiretamente da mesma.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            Uma vez declarado o vencimento antecipado das Notas Comerciais, cabe ao Titular requerer a execução judicial ou extrajudicial, do Aval. O Aval poderá ser excutido e exigido pela mesma quantas vezes forem necessárias para o integral pagamento das obrigações garantidas pelo Aval contra o Avalista.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            O Avalista deverá pagar as Obrigações Garantidas em até 2 (dois) Dias Úteis contados da data do respectivo inadimplemento da Emissora.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            Fica desde já certo e ajustado que a inobservância, pelo Titular, dos prazos para execução do Aval não ensejará, sob hipótese nenhuma, perda de qualquer direito ou faculdade aqui previsto, podendo o Aval ser excutido e exigido pelo Titular, judicial ou extrajudicialmente, quantas vezes forem necessárias até a integral liquidação das Obrigações Garantidas.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            Os pagamentos que vierem a ser realizados pelo Avalista com relação às Notas Comerciais serão realizados de modo que o Titular receba do Avalista os valores que lhes seriam entregues caso esses pagamentos tivessem sido realizados pela Emissora, não cabendo ao Avalista realizar qualquer dedução que não seria realizada pela Emissora, caso a Emissora tivesse realizado o respectivo pagamento.
                            {'\n'}
                        </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.textHeader}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />

                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.h1, { marginTop: 40 }]}>4. VENCIMENTO ANTECIPADO</Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}<Text style={styles.textBold}>a.       </Text> Se ocorrer inadimplemento de qualquer obrigação assumida pela Emissora em consonância com as cláusulas e condições aqui estabelecidas, principalmente no que tange ao pagamento das parcelas devidas em decorrências da celebração deste Termo Constitutivo e emissão das Notas Comerciais;
                            {'\n'}{'  '}<Text style={styles.textBold}>b.       </Text> Se for comprovada a falsidade de qualquer declaração, informação ou documento que houver sido respectivamente firmada, prestada ou entregue pela Emissora;
                            {'\n'}{'  '}<Text style={styles.textBold}>c.        </Text> Se a Emissora sofrer quaisquer medidas judiciais ou extrajudiciais, que por qualquer forma, possam afetar negativamente os créditos das Notas Comerciais; e
                            {'\n'}{'  '}<Text style={styles.textBold}>d.        </Text> Se, sem o expresso consentimento dos credores das Notas Comerciais ocorrer a transferência a terceiros dos direitos e obrigações da Emissora previstos neste Termo Constitutivo.
                            {'\n'}{'  '}<Text style={styles.textBold}>e.</Text> Se ocorrer a transferência a terceiros dos direitos e obrigações da Emissora, previstos neste Termo Constitutivo.
                        </Text>
                        <Text style={[styles.h1, { marginTop: 40 }]}>5. DAS DECLARAÇÕES</Text>
                        <Text style={styles.text5}>A Emissora declara e garante que:
                            {'\n'}
                            {'\n'}{'  '}<Text style={styles.textBold}>a.       </Text> Possui plena capacidade e legitimidade para celebrar o presente Termo Constitutivo, realizar todas as operações e cumprir todas as obrigações assumidas tendo tomado todas as medidas de natureza societária, conforme aplicável, e outras eventualmente necessárias para autorizar a sua celebração, implementação e cumprimento de todas as obrigações constituídas;
                            {'\n'}{'  '}<Text style={styles.textBold}>b.       </Text> A celebração deste Termo Constitutivo e o cumprimento das obrigações nele dispostos: (a) não violam qualquer disposição contida nos seus documentos societários, conforme aplicável; (b) não violam qualquer lei, regulamento, decisão judicial, administrativa ou arbitral, aos quais a respectiva parte esteja vinculada; (c) não exigem qualquer consentimento ação ou autorização, prévia ou posterior, de terceiros;
                            {'\n'}{'  '}<Text style={styles.textBold}>c.        </Text> Este Termo Constitutivo é validamente celebrado e constitui obrigação legal, válida, vinculante e exequível, de acordo com os seus termos;
                            {'\n'}{'  '}<Text style={styles.textBold}>d.        </Text> Está apta a cumprir as obrigações ora previstas neste Termo Constitutivo e agirá em relação às mesmas de boa-fé e com lealdade;
                            {'\n'}{'  '}<Text style={styles.textBold}>e.        </Text> Não se encontra em estado de necessidade ou sob coação para celebrar este Termo Constitutivo e/ou quaisquer contratos e compromissos a ela relacionados e acessórios; e,
                            {'\n'}{'  '}<Text style={styles.textBold}>f.</Text> É devidamente estruturada, qualificada e capacitada para entender a estrutura financeira e jurídica objeto deste Termo Constitutivo.

                        </Text>
                        <Text style={[styles.h1, { marginTop: 40 }]}>6. DO ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS</Text>
                        <Text style={styles.text5}>
                            Na hipótese de inadimplemento ou mora dos valores devidos no âmbito deste Termo Constitutivo, a Emissora estará obrigada a pagar ao titular, cumulativamente, além da quantia correspondente à dívida em aberto, os seguintes encargos:
                            {'\n'}{'  '}<Text style={styles.textBold}>a.       </Text> Juros remuneratórios nos mesmos percentuais das taxas constantes neste Termo Constitutivo, calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento;
                            {'\n'}
                        </Text>
                        <Text style={styles.text5}>
                            <Text style={styles.textBold}>b.       </Text> Juros de mora à razão de 1% a.m. (um por cento ao mês), calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento pela Emissora;
                            {'\n'}{'  '}<Text style={styles.textBold}>c.        </Text> Multa contratual, de natureza não compensatória, de 2% (dois por cento) incidente sobre o montante total atualizado (incluindo juros remuneratórios e juros de mora) do valor devido e não pago; e
                            {'\n'}{'  '}<Text style={styles.textBold}>d.        </Text> Na hipótese do Titular vir a ser compelido a recorrer a meios administrativos ou judiciais para receber os valores devidos no âmbito deste Termo Constitutivo, as despesas de cobrança administrativa, limitadas a 10% (dez por cento) sobre o valor do saldo devedor deste Termo Constitutivo e, havendo procedimento judicial, custas processuais e honorários advocatícios, estes fixados judicialmente.
                        </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.textHeader}>
                            <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />

                        </View>

                        <Text style={styles.h1}>7. DISPOSIÇÕES GERAIS</Text>
                        <Text style={styles.textLine}>Da não Conversibilidade
                        </Text>
                        <Text style={styles.text1}>As notas objeto desta Emissão não são conversíveis em quotas Emissora.</Text>
                        <Text style={styles.textLine}>Renúncia
                        </Text>
                        <Text style={styles.text1}>
                            Não se presume a renúncia a qualquer dos direitos decorrentes das Notas Comerciais. Desta forma, nenhum atraso, omissão ou liberalidade no exercício de qualquer direito, faculdade ou prerrogativa que caiba ao Titular, em razão de qualquer inadimplemento da Emissora, prejudicará o exercício de tais direitos, faculdades ou remédios, ou será interpretado como constituindo uma renúncia aos mesmos ou concordância com tal inadimplemento, nem constituirá novação ou modificação de quaisquer outras obrigações assumidas pela Emissora neste instrumento, ou precedente no tocante a qualquer outro inadimplemento ou atraso.

                        </Text>
                        <Text style={styles.textLine}>Título Executivo Extrajudicial e Execução Específica
                        </Text>
                        <Text style={styles.text1}>
                            Este instrumento e as Notas Comerciais constituem títulos executivos extrajudiciais, nos termos do artigo 48 da Lei 14.195 de 2021, sem prejuízo do direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste instrumento.
                        </Text>
                        <Text style={styles.textLine}>Da Contratação dos Prestadores de Serviços da Emissão – Outorga de Poderes
                        </Text>
                        <Text style={styles.text1}>
                            A Emissora, neste ato, em caráter irrevogável e irretratável, autoriza o Estruturador a representá-la perante os prestadores de serviço envolvidos na Emissão, inclusive contratá-los em seu nome, desde que a obrigatoriedade de pagamento seja do próprio Estruturador.
                        </Text>
                        <Text style={styles.textLine}>Comunicações
                        </Text>
                        <Text style={styles.text1}>
                            As notificações, instruções e comunicações serão consideradas entregues quando recebidas sob protocolo ou com “aviso de recebimento” expedido pela Empresa Brasileira de Correios, ou por telegrama nos endereços do preâmbulo. As comunicações enviadas por correio eletrônico serão consideradas recebidas na data de seu envio, desde que seu recebimento seja confirmado por meio de recibo emitido pelo remetente (recibo emitido pela máquina utilizada pelo remetente). A mudança de qualquer dos endereços acima deverá ser imediatamente comunicada às demais Partes pela Parte que tiver seu endereço alterado.
                        </Text>
                        <Text style={styles.textLine}>Irrevogabilidade
                        </Text>
                        <Text style={styles.text1}>
                            As Notas Comerciais e o presente instrumento são celebrados em caráter irrevogável e irretratável, obrigando as partes e seus sucessores a qualquer título.
                        </Text>
                        <Text style={styles.textLine}>Cômputo do Prazo
                        </Text>
                        <Text style={styles.text1}>
                            Exceto se de outra forma especificamente disposto neste instrumento, os prazos estabelecidos no presente instrumento serão computados de acordo com a regra prescrita no artigo 132 do Código Civil, sendo excluído o dia do começo e incluído o do vencimento.
                        </Text>
                        <Text style={styles.textLine}>Assinatura Eletrônica
                        </Text>
                        <Text style={styles.text1}>
                            Para os fins do artigo 10, parágrafo 2º, da Medida Provisória nº 2.200-2, de 24 de agosto de 2001, as Partes acordam e aceitam que este instrumento e qualquer aditamento podem ser formalizados mediante aposição de</Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.textHeader}>
                            <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                        </View>
                        <Text style={styles.text5}>
                            (i) assinaturas digitais, com processo de certificação disponibilizada pela Infraestrutura de Chaves Públicas Brasileira – ICP-Brasil ou (ii) assinaturas eletrônicas que utilizem certificados não emitidos pela ICP-Brasil para comprovação da autoria e integridade de documentos em forma eletrônica, sendo ambas as formas de assinatura expressamente reconhecidas pelas Partes como válidas, nos termos do Artigo 10, §2º da Medida Provisória nº 2.200-2, de 24 de agosto de 2001.
                        </Text>
                        <Text style={styles.textLine}>Data de Integralização
                        </Text>
                        <Text style={styles.text1}>
                            Para os fins deste Termo Constitutivo, Data de Integralização é(são) a(s) data(s) em que a Emissora receberá o crédito decorrente das Notas Comerciais, subscrita(s) por seus(s) respectivos(s) titular(es).
                        </Text>
                        <Text style={styles.textLine}>Boa-fé e Equidade
                        </Text>
                        <Text style={styles.text1}>
                            As Partes declaram, mútua e expressamente, que este instrumento foi celebrado respeitando-se os princípios de probidade e de boa-fé, por livre, consciente e firme manifestação de vontade das Partes e em perfeita relação de equidade.
                        </Text>
                        <Text style={styles.textLine}>Lei Aplicável
                        </Text>
                        <Text style={styles.text1}>
                            Este instrumento é regido pelas Leis da República Federativa do Brasil.
                        </Text>
                        <Text style={styles.textLine}>Foro
                        </Text>
                        <Text style={styles.text1}>
                            Fica eleito o foro da Comarca da Cidade de {emissor.cidade}, Estado de {estadoPorExtenso(emissor.estado)}, para dirimir quaisquer dúvidas ou controvérsias oriundas deste instrumento, com renúncia a qualquer outro, por mais privilegiado que seja.
                        </Text>
                        <Text style={[styles.textCenter, { marginBottom: 50, marginTop: 10 }]}>
                            {`${investidor.cidade}, ${dataHoje}`}
                        </Text>
                        <Text style={[styles.text2, { textAlign: 'center', width: '50%', alignSelf: 'center', marginBottom: 100 }]}>
                            {emissor.razaoSocial}{'\n'}
                            Na qualidade de Emissora
                        </Text>
                        <Text style={[styles.text5, { marginBottom: 20 }]}>
                            TESTEMUNHAS:
                        </Text>
                        <Text style={[styles.text2, { textAlign: 'center', width: '80%', alignSelf: 'center', marginBottom: 100 }]}>
                            Nome: Julio Cesar de Lima Kamezawa                     Nome: Rosana Winterink
                            CPF: 402.361.038-06                                                     CPF: 222.201.218-01

                        </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.textHeader}>
                            <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                        </View>
                        <Text style={[styles.centerH1, { marginBottom: 20, paddingHorizontal: 20 }]}>
                            ANEXO I – MODELO DE BOLETIM DE SUBSCRIÇÃO DAS NOTAS COMERCIAIS
                        </Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                BOLETIM DE SUBSCRIÇÃO DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA

                                COLOCAÇÃO PRIVADA, DA {emissor.razaoSocial}.
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                CARACTERÍSTICAS DA EMISSÃO
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Emissão, pela {emissor.razaoSocial} pessoa jurídica situada na {emissor.rua}, Nº {emissor.numero}, {emissor.cidade}/{emissor.estado} – CEP: {emissor.cep} inscrita no CNPJ/MF sob o nº {emissor.cnpj} ("Companhia"), de 1 notas
                                comerciais, com valor nominal unitário de {valorTotal} e vencimento em {dataDoVencimento}, da sua {dados.emissao} emissão ("Notas Comerciais" e "Emissão").
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                QUALIFICAÇÃO DO SUBSCRITOR</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Nome / Razão Social: {investidor.razaoSocial}</Text>
                            <Text style={styles.cell3}>
                                CNPJ /MF: {investidor.cnpj}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Endereço: {investidor.rua}</Text>
                            <Text style={styles.cell3}>
                                Nº: {investidor.numero}</Text>
                            <Text style={styles.cell3}>
                                Complemento:{investidor.complemento}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Bairro: {investidor.bairro}</Text>
                            <Text style={styles.cell3}>
                                CEP: {investidor.cep}</Text>
                            <Text style={styles.cell3}>
                                Cidade: {investidor.cidade}</Text>
                            <Text style={styles.cell3}>
                                U.F.: {investidor.estado}</Text>
                            <Text style={styles.cell3}>
                                País: {'Brasil'}</Text>
                            <Text style={styles.cell3}>
                                Telefone: {investidor.telefone}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                NOTAS COMERCIAIS SUBSCRITAS</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, styles.textBold, { borderRightWidth: 0 }]}>
                                QUANTIDADE</Text>
                            <Text style={[styles.cell3, styles.textBold]}>
                                PREÇO TOTAL UNITÁRIO</Text>
                            <Text style={[styles.cell3, styles.textBold]}>
                                VALOR EM R$:</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                1</Text>
                            <Text style={styles.cell3}>
                                {allInfoArray[13]}</Text>
                            <Text style={styles.cell3}>
                                {allInfoArray[13]}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }]}>O Preço Total Unitário será mantido em cada data de integralização, caso distintas.. A

                                rentabilidade apenas se inicia após a Data de Integralização.</Text>
                        </View>
                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>FORMA SUBSCRIÇÃO E INTEGRALIZAÇÃO</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, styles.textBold]}>MEIO DE INTEGRALIZAÇÃO:{'\n'}{'\n'}{`${emissor.meioPagamento}`}</Text>
                            <Text style={[styles.cell3, { textAlign: 'center' }]}><Text style={styles.textBold}>DATA DE INTEGRALIZAÇÃO:</Text>{'\n'}{'\n'}A efetiva integralização deverá ser realizada
                                em até 30 (trinta) dias corridos, contados da
                                data de assinatura do presente Boletim de

                                Subscrição.</Text>

                        </View>

                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>CONTA BANCÁRIA DA EMISSORA</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº Do Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Agência</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Conta</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>{emissor.banco}</Text>
                            <Text style={styles.cell3}>{emissor.bankNumber}</Text>
                            <Text style={styles.cell3}>{emissor.agency}</Text>
                            <Text style={styles.cell3}>{emissor.conta}</Text>
                        </View>
                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>CONTA BANCÁRIA DO ESCRITURADOR</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº Do Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Agência</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Conta</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>Singulare</Text>
                            <Text style={styles.cell3}>363</Text>
                            <Text style={styles.cell3}>0001</Text>
                            <Text style={styles.cell3}>45-1</Text>
                        </View>
                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>CONTA BANCÁRIA DO SUBSCRITOR</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº Do Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Agência</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Conta</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>{investidor.banco}</Text>
                            <Text style={styles.cell3}>{investidor.numeroDoBanco}</Text>
                            <Text style={styles.cell3}>{investidor.agencia}</Text>
                            <Text style={styles.cell3}>{investidor.conta}</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.cell3}>
                                <Text style={[{ fontSize: 12, paddingBottom: 10, }]}>
                                    Declaro, para todos os fins, que estou de acordo com as condições expressas no presente
                                    boletim, bem como declaro ter obtido exemplar do Termo Constitutivo.{'\n'}
                                </Text>
                                <Text style={[{ textAlign: 'center', paddingBottom: '20px', fontSize: 12, borderBottomWidth: 1, borderBottomColor: 'black' }]}>
                                    {`${investidor.cidade}, ${dataHoje}`}{'\n'}</Text>
                                <View style={[{ borderBottomWidth: 1, borderBottomColor: 'black', marginTop: 30, width: '50%', textAlign: 'center', alignSelf: 'center' }]}></View>
                                <Text style={[styles.text, { textAlign: 'center', }]}>Assinatura do Subscritor ou Representante Legal</Text></View>

                        </View>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.textHeader}>
                            <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                        </View>
                        <Text style={[styles.h1, { marginTop: 10, marginBottom: 10 }]}>Anexo II.</Text>
                        <View style={styles.downTable}>
                            <View style={styles.tr}>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>Período</Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>Vencimento</Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '90px' }]}>Parcela</Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '91px' }]}>Amortização</Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '88px' }]}>Juros</Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '100px' }]}>Saldo Devedor</Text>
                            </View>

                            <View style={styles.tr}>
                                <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>0</Text>
                                <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                    {now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>0</Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>0</Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>0</Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>{allInfoArray[13]}</Text>
                            </View>

                            {calcData && calcData.map((data, index) => (
                                <View key={index} style={styles.tr}>
                                    <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>{data[0]}</Text>
                                    <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                        {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                        {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>
                                        {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>
                                        {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>
                                        {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer >
    )
    
}

export default SingulareSemGarantia;
