import React, { useEffect, useState ,useRef} from "react";
import axios from "axios";
import '../styles/PostNote.css'

import { IoIosCloudUpload } from 'react-icons/io';
import { formatFileSize } from "../utils/Tools";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { saveAs } from "file-saver";
import { PDFDownloadLink, PDFViewer, pdf, renderToBuffer, renderToFile } from "@react-pdf/renderer";
import html2pdf from 'html2pdf.js';
import Singulare from "../utils/pdfs/SingularePdf";
import ReactDOM from 'react-dom'; // Adicione esta linha
import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import MinutaSingulare from "../utils/pdfs/minutaSingulare";
import prestacaoServicoSingulare from "../utils/pdfs/prestacaoServicoSingulare";



//import { useReactToPrint } from 'react-to-print';

import { renderToStaticMarkup } from 'react-dom/server';
import Emissors from "./Emissors";

const PostNote = ({ onNotePosted ,dados}) => {
  
    const user = JSON.parse(localStorage.getItem('user'));
    const allInfoArray = JSON.parse(localStorage.getItem('allInfoArray'));
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const userRef = user.data.cnpj;
    const [fileMinuta, setFileMinuta] = useState(null);
    const [fileCondicoes, setFileCondicoes] = useState(null);
    const [fileContrato, setFileContrato] = useState(null);
    const [fileFaturamento, setFileFaturamento] = useState(null);
    const [fileDocumentos, setFileDocumentos] = useState(null);
    const [mainDocuments, setMainDocuments] = useState([]);
    const [documentFields, setDocumentFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [garantias, setGarantias] = useState(null);
    const [minutaButtonClass, setMinutaButtonClass] = useState("customFileInput");
    const [condicoesButtonClass, setCondicoesButtonClass] = useState("customFileInput");
    const [contratoButtonClass, setContratoButtonClass] = useState("customFileInput");
    const [faturamentoButtonClass, setFaturamentoButtonClass] = useState("customFileInput");
    const [garantiasButtonClass, setGarantiasButtonClass] = useState("customFileInput");
    const [documentosButtonClass, setDocumentosButtonClass] = useState("customFileInput");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileUploadQueue, setFileUploadQueue] = useState([]);
    const [selectedOption, setSelectedOption] = useState("Trustee");
    const [downloadUrl, setDownloadUrl] = useState(null);
    const calcDataState = useSelector((state) => state.calcData.calcData)
    const allInfoArrayState = useSelector((state) => state.finalTotal.finalTotal);
    const calcData2 = JSON.parse(localStorage.getItem('calcData'));
    const sacData = JSON.parse(localStorage.getItem('sacData'));
    let calcData = calcData2 ? calcData2 : sacData
    const linhaDoVencimento = calcData ? calcData[calcData.length - 1] : sacData[sacData.length - 1]
    const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    const handlePDFGenerated = (blob) => {
        setFileMinuta(blob);
        console.log(blob, 'blob');
    };
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        const pdfBlob = localStorage.getItem('pdfBlob');
        console.log(pdfBlob);
        if (mainDocuments.length < (documentFields.length + 1)) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [mainDocuments, documentFields]);
    const handleRemoveFile = (index) => {
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
    };
    const handleFileChange = (e, fileType) => {
        const selectedFiles = e.target.files;

        if (selectedFiles.length === 1) {

            const file = selectedFiles[0];
            setUploadedFiles([...uploadedFiles, { fileType, file }]);
            setSuccessMessage(true);
            console.log(uploadedFiles);
            console.log(file);
        };
    };
    const garantiaData = JSON.parse(localStorage.getItem('garantiaData'));
    const handleUploadFiles = async () => {
        setLoading(true);
        try {
            const uploadedUrls = await Promise.all(
                uploadedFiles.map(async ({ fileType, file }) => {
                    const uploadedUrl = await uploadFile(file);
                    return { fileType, uploadedUrl };
                })
            );
            const updatedFiles = {};
            uploadedUrls.forEach(({ fileType, uploadedUrl }) => {
                updatedFiles[fileType] = uploadedUrl;
            });
            setFileMinuta(updatedFiles['minuta']);
            console.log(updatedFiles['minuta'], 'minuta');
            setFileCondicoes(updatedFiles['condicoes']);
            setFileContrato(updatedFiles['contrato']);
            setFileFaturamento(updatedFiles['faturamento']);
            setGarantias(updatedFiles['garantias']);
            setFileDocumentos(updatedFiles['documentos']);

            setSuccessMessage("Arquivos enviados com sucesso.");
            setTimeout(() => setSuccessMessage(false), 3000);

            // Após o envio bem-sucedido dos arquivos, envie os dados para o servidor
            sendFormDataToServer(updatedFiles);
        } catch (error) {
            console.error("Erro ao enviar arquivos:", error);
            setLoading(false);
        }
    };
   

    const sendFormDataToServer = async (uploadedUrls) => {
        const serverUrl = process.env.REACT_APP_BASE_URL

        // Construa o objeto de dados que você deseja enviar para o servidor
        const dataToSend = {
            urlMinuta: uploadedUrls['minuta'],
            urlCondicoes: uploadedUrls['condicoes'],
            urlContrato: uploadedUrls['contrato'],
            urlFaturamento: uploadedUrls['faturamento'],
            urlDocumentos: uploadedUrls['documentos'],
            status: 'Enviado',
            valorOperacao: allInfoArray[5],
            parcelas: formValues.parcelas,
            vencimento: formValues.vencimento,
            razaoEmissor: emissor.razaoSocial,
            escrituradora: selectedOption,
        };
        try {            
            const response = await axios.post(`${serverUrl}/emitted/sendEmail/${userRef}`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json', // Defina o tipo de conteúdo como JSON
                },
            });
            console.log('Resposta do servidor:', response.data);

            setFileMinuta(null);
            setFileCondicoes(null);
            onNotePosted();
            navigate('/emitted');
        } catch (error) {
            console.error('Erro na solicitação:', error);
        } finally {
            setLoading(false);
        }
    };

    const uploadFile = async (file) => {
        const serverUrl = process.env.REACT_APP_BASE_URL
        const formData = new FormData();
        formData.append(`file`, file);

        try {
            const response = await axios.post(`${serverUrl}/register/sendEmail`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Se o upload for bem-sucedido, o servidor deve responder com o URL do arquivo
            const uploadedUrl = response.data.url;
            console.log('URL do arquivo:', uploadedUrl);
            return uploadedUrl;
        } catch (error) {
            // Lida com erros
            console.error('Erro na solicitação:', error);
            throw error; // Rejeita a promise para que o erro seja capturado no local apropriado
        }
    };
    useEffect(() => {
        // Verifique se todos os documentos foram enviados para habilitar o botão `handleSubmit`
        const expectedDocumentCount = documentFields.length + 1; // +5 para os outros tipos de documentos
        if (uploadedFiles.length > expectedDocumentCount) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [uploadedFiles, documentFields]);

    const navigate = useNavigate();
    const handleSubmit = async () => {
        console.log("Enviando arquivo:", uploadedFiles);
        handleUploadFiles();
        setLoading(true);
        const serverUrl = process.env.REACT_APP_BASE_URL
        if (
            fileMinuta &&
            fileCondicoes &&
            fileContrato &&
            fileFaturamento &&
            fileDocumentos &&
            allInfoArray[5] !== null &&
            formValues.parcelas !== null &&
            dataDoVencimento !== null &&
            emissor.razaoSocial !== null
        ) {
            // Construa o objeto de dados que você deseja enviar para o servidor
            const dataToSend = {
                urlMinuta: fileMinuta,
                urlCondicoes: fileCondicoes,
                urlContrato: fileContrato,
                urlFaturamento: fileFaturamento,
                urlDocumentos: fileDocumentos, // Certifique-se de substituir isso pelo valor desejado
                status: 'Enviado',
                valorOperacao: allInfoArray[5],
                parcelas: formValues.parcelas,
                vencimento: dataDoVencimento,
                razaoEmissor: emissor.razaoSocial
            };
            console.log(dataToSend,'')

            try {
                const response = await axios.post(`${serverUrl}/emitted/sendEmail/${userRef}`, dataToSend, {
                    headers: {
                        'Content-Type': 'application/json', // Defina o tipo de conteúdo como JSON
                    },
                });
                console.log('Resposta do servidor:', response.data);
                setFileMinuta(null);
                setFileCondicoes(null);
                onNotePosted();
                navigate('/emitted');
            } catch (error) {
                console.error('Erro na solicitação:', error);
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Dados nulos encontrados. Não é possível enviar a solicitação.');
        }
    };
    const addDocumentField = () => {
        setDocumentFields([...documentFields, ""]);
    };
    const removeDocumentField = (index) => {
        const updatedFields = [...documentFields];
        updatedFields.splice(index, 1);
        setDocumentFields(updatedFields);
    };
 
    function blobToArrayBuffer(blob) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsArrayBuffer(blob);
        });
      }
      
 
    //Funcao que envia o e-mail e os dados da nota
    const handleUploadFilesEscrituracao = async () => {
        let dadosArray={
            dados:dados,
            allInfoArray:allInfoArrayState,
            calcData:calcDataState
        }
        const emissor = JSON.parse(localStorage.getItem('emissor'));
        const investidor = JSON.parse(localStorage.getItem('investidor'));
        const formData = new FormData();
        const garantiaId = JSON.parse(localStorage.getItem('garantiaIdString'));
       //console.log(garantiaId,'garantia')
        let i =0
        setLoading(true);
        formData.append('idEmissor', emissor.cpf);
        formData.append('garantiaId', garantiaId);
        formData.append('i', i);
        formData.append('idInvestidor', investidor._id);
        //Dados da nota
        formData.append('userRef', userRef);
        formData.append('status', 'Enviado');
        formData.append('valorOperacao', allInfoArray[5]);
        formData.append('parcelas', formValues.parcelas);
        formData.append('vencimento', dataDoVencimento);
        formData.append('escrituradora', selectedOption);
        formData.append('razaoEmissor', emissor.razaoSocial,);
        formData.append('emails', ['cesar@c2ss.com.br','denis@c2ss.com.br']);
        MinutaSingulare(dadosArray, (blob) => {
            // saveAs(blob,'minuta.pdf')
            formData.append('pdf', blob, 'minuta.pdf');
           
         });
        // MinutaSingulare(dadosArray, (blob) => {
         prestacaoServicoSingulare(dadosArray, (blob) => {
          //  saveAs(blob,'prestacao.pdf')
            formData.append('contrato', blob, 'contrato.pdf');
         });
        axios.post(process.env.REACT_APP_BASE_URL + '/arquivos', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            })
            .then((response) => {
                 setLoading(false);
                  setShowSuccess(true);
                 setTimeout(() => {
                         setShowSuccess(false);
                }, 5000);
                console.log('Arquivo enviado com sucesso!', response.data);
                // Faça algo com a resposta do servidor, se necessário
            })
            .catch((error) => {
                    console.error('Erro ao enviar o arquivo para o servidor:', error);
        });
         console.log(formData,'formData')
    };

    return (
        <div className="uploadForm">
            <div className="uploadedFilesList">
                {uploadedFiles.map((uploadedFile, index) => (
                    <div key={index} className="uploadedFileItem">
                        <span>{uploadedFile.fileType}</span>
                        <span>{formatFileSize(uploadedFile.file.size)}</span>
                        <button className="button11" onClick={() => handleRemoveFile(index)}>Remover</button>
                    </div>
                ))}
            </div>
            {/*
                <label className="divInputsDoc5 ">
                    <span htmlFor="" className="labelInputsDoc">Selecione a Escrituradora:</span>
                    <select
                        className="selectDoc"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        required
                    >
                        <option value="Trustee">Trustee</option>
                        <option value="Vortx">Vortx</option>
                        <option value="Singulare">Singulare</option>
                    </select>
                </label>
            */}
           {/* <label className={minutaButtonClass}>
                <input type="file" onChange={(e) => { handleFileChange(e, 'minuta') }} style={{ display: "none" }} />
                {loading ? (
                    <FaSpinner className="uploadIcon" spin />
                ) : (
                    <IoIosCloudUpload className="uploadIcon" />
                )}
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                    {loading ? "Enviando..." : "ANEXAR MINUTA ASSINADA"}
                </span>
            </label>
            <label className={condicoesButtonClass}>
                <input type="file" onChange={(e) => { handleFileChange(e, 'condicoes') }} style={{ display: "none" }} />
                <IoIosCloudUpload className="uploadIcon" />
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>ANEXAR CONTRATO DE PRESTAÇÃO DE SERVIÇO</span>
            </label>
            <label className={contratoButtonClass}>
                <input type="file" onChange={(e) => { handleFileChange(e, 'faturamento') }} style={{ display: "none" }} />
                <IoIosCloudUpload className="uploadIcon" />
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>RELATÓRIO DE FATURAMENTO</span>
                </label>
            <label className={contratoButtonClass}>
                <input
                    type="file"
                    name="files"
                    accept=".pdf, .doc, .docx"
                    onChange={(e) => handleFileChange(e, 'contratos')}

                    style={{ display: "none" }}
                />
                <IoIosCloudUpload className="uploadIcon" />
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>CONTRATO SOCIAL/ATA DE CONSTITUIÇÃO</span>
                </label>/*}
            <label className={contratoButtonClass}>
                <input
                    type="file"
                    name="files"
                    accept=".pdf, .doc, .docx"
                    onChange={(e) => handleFileChange(e, 'garantias')}
                    style={{ display: "none" }}
                />
                <IoIosCloudUpload className="uploadIcon" />
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>DOCUMENTOS DE GARANTIA</span>
                </label>
            <label className={documentosButtonClass}>
                <input
                    type="file"
                    name="files"
                    accept=".pdf, .doc, .docx"
                    onChange={(e) => handleFileChange(e, 'documentos')}
                    style={{ display: "none" }}
                />
                <IoIosCloudUpload className="uploadIcon" />
                <span className="uploadText" style={{ textAlign: 'center' }}>DOCUMENTOS DOS SÓCIOS</span>
                </label>*/}

             {loading && <p>Enviando...</p>} 
             {showSuccess && <div className="divInputsDoc5"><button><p>A solicitação foi efetuada com sucesso!</p></button></div>}
            <div style={{ width: "100%", height: '5vh', marginBottom: '5vh' }}>
            {!loading && !showSuccess && (
                <div className="divInputsDoc5">
        {/** Ajuste especial para Trecon decomplicando a geração */}
        {/*<button onClick={handleUploadFilesEscrituracao} disabled={loading}>
          SOLICITAR ESCRITURAÇÃO
            </button>*/}
        <button onClick={handleUploadFilesEscrituracao} disabled={loading}>
            Solicitar Emissão de Nota Comercial
        </button>
        </div>
      )}
              {/*  <button
                    onClick={handleUploadFilesEscrituracao}
                >
                    SOLICITAR ESCRITURAÇÃO
                </button>*/}
            </div>
        </div>
    );
};

export default PostNote;
