import React, { useEffect, useState,useRef  } from "react";
import { useTable, usePagination } from 'react-table';
import axios from "axios";
import '../styles/Emitted.css'
import Header from "../components/Header";
import ShowNotes from "../utils/Modals/ShowNotes";
import { AiOutlineEye } from 'react-icons/ai';

const EmittedNotes = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userRef = user.data.cnpj;
    const emailUserRef = user.data.email;
    const [emittedNotes, setEmittedNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState(null); // Estado para armazenar o arquivo selecionado
    const [selectedNote, setSelectedNote] = useState(null); // State to store the selected note for the modal
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar a abertura do modal
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [noteToDelete, setNoteToDelete] = useState(null);
    const [deletionSuccess, setDeletionSuccess] = useState(false);
    const tableRef = useRef(null);
    const [pageIndex, setPageIndex] = useState(0);
    
    const showConfirmation = (note) => {
        setConfirmationVisible(true);
        setNoteToDelete(note);
    };


    const openModal = (note) => {
        console.log("Opening modal for note:", note);
        setSelectedNote(note);
        setIsModalOpen(true); // Abre o modal quando um botão de detalhes é clicado
    };

    const closeModal = () => {
        setSelectedNote(null);
        setIsModalOpen(false); // Fecha o modal quando o botão de fechar é clicado
    };

    useEffect(() => {
        if (user && user.data && user.data.email) {
            const cnpj = (user.data.cnpj) ?? 'null';
            axios.get(`${process.env.REACT_APP_BASE_URL}/emitted/getPdfs/${cnpj}?userRef=${emailUserRef}`)
                .then((response) => {
                    const data = response.data;
                    setEmittedNotes(data);
                })
                .catch((error) => {
                    console.error("Erro ao buscar notas emitidas:", error);
                }).finally(()=>{
                    setLoading(false);
                });
        } else {
            console.error("Usuário não autenticado ou CNPJ indisponível.");
            setLoading(false);
        }
    }, [deletionSuccess, pageIndex]);

    useEffect(() => {
        const adjustTableHeight = () => {
            if (tableRef.current) {
              const viewportHeight = window.innerHeight;
              const tableOffsetTop = tableRef.current.getBoundingClientRect().top;
              const maxHeight = viewportHeight - tableOffsetTop - 20; // 20 pixels de folga
              tableRef.current.style.maxHeight = `${maxHeight}px`;
            }
          };
          adjustTableHeight();
          window.addEventListener('resize', adjustTableHeight);
          return () => {
            window.removeEventListener('resize', adjustTableHeight);
          };
    }, []);

    const bytesToMB = (bytes) => {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('pt-BR', options);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const deleteNote = () => {
        if (noteToDelete) {
            // Fazer a solicitação de exclusão usando Axios
            axios
                .delete(`${process.env.REACT_APP_BASE_URL}/emitted/pdfs/${noteToDelete._id}`)
                .then((response) => {
                    // Atualizar a lista de notas após a exclusão
                    const updatedNotes = emittedNotes.filter((note) => note._id !== noteToDelete._id);
                    setEmittedNotes(updatedNotes);
                    setDeletionSuccess(true); // Defina o estado de sucesso da exclusão como verdadeiro
                })
                .catch((error) => {
                    console.error("Erro ao excluir nota:", error);
                })
                .finally(() => {
                    setConfirmationVisible(false);
                    setNoteToDelete(null);
                });
        }
    };


    const cancelDelete = () => {
        setConfirmationVisible(false);
        setNoteToDelete(null);
    };

  
    const columns = React.useMemo(
          () => [
            {
              Header: 'Origem',
              accessor: 'origemRazaoSocial',
            },
            {
              Header: 'Razão Social',
              accessor: 'razaoEmissor',
            },
            {
              Header: 'Valor da Operação',
              accessor: 'valorOperacao',
            },
            {
              Header: 'Parcelas',
              accessor: 'parcelas',
            },
            {
              Header: 'Data de Vencimento',
              accessor: 'vencimento',
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
            {
              Header: 'Detalhes',
              accessor: 'details',
              Cell: ({ row }) => (
                <div className="emittedShowDetails" onClick={() => openModal(row.original)}>
                  <AiOutlineEye />
                </div>
              ),
            },
          ],
          []
        );
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          page,
          prepareRow,
          canPreviousPage,
          canNextPage,
         // nextPage,
        //  previousPage,
          pageOptions,
          
        } = useTable(
          {
            columns,
            data: emittedNotes,
            initialState: { pageIndex: pageIndex },
          },
          usePagination
        );
        const nextPage = () => {
                setPageIndex(pageIndex + 1);
        };
        const previousPage = () => {
            setPageIndex(pageIndex - 1);
          };
       

    


    return (
        <body className="emittedBody">
            <div className="fixed">
                <Header selected={'notes'} />
            </div>
            <main className="emittedMain">
                <div className="ahead">
                    <h2 className="h2Emitted">Notas Emitidas:</h2>
                    <div className="div1">
                        <h3>Bem Vindo, {user.data.razaoSocial}</h3>
                    </div>
                </div>
                {loading ? (
                    <p className="pEmitted">Carregando...</p>
                ) : (
                    <div className="divEmitted">
                             <div className="pagination">
                                {pageOptions.length > 1 && (
                                    <>
                                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Anterior
                                    </button>
                                    <span>
                                        Página{' '}
                                        <strong>
                                        {pageIndex + 1} de {pageOptions.length}
                                        </strong>{' '}
                                    </span>
                                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                                        Próxima
                                    </button>
                                    </>
                                )}
                            </div>
                            <table className="tableEmitted">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {deletionSuccess && (
                                <div className="success-message">
                                    Nota excluída com sucesso!
                                </div>
                            )}
                    </div>
                   
                )}

                {confirmationVisible && (
                    <div className="confirmation-modal">
                        <p>Deseja realmente excluir esta nota?</p>
                        <button className="button111" onClick={deleteNote}>Sim</button>
                        <button className="button111" onClick={cancelDelete}>Cancelar</button>
                    </div>
                )}

            </main>

            {isModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal-backdrop" onClick={closeModal}></div>
                    <div className="modal-container">
                        <ShowNotes note={selectedNote} onClose={closeModal} />
                    </div>
                </div>
            )}
        </body>
    );

};

export default EmittedNotes;
