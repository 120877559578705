import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { FaTrashCan } from "react-icons/fa6";
import { RiEdit2Line } from "react-icons/ri";
import axios from "axios";
import '../styles/Client.css'
import { formatCnpj } from "../utils/Tools";

const Client = () => {
    const [affiliates, setClients] = useState([]);
    const [confirmationActive, setConfirmationActive] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [affiliateToDelete, setClientToDelete] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState(""); // Para exibir mensagens de sucesso ou erro
    const [deleteError, setDeleteError] = useState(false); // Para indicar erros na exclusão
    const [editingClientId, setEditingClientId] = useState(null); // ID do Cliente em edição
    const [editedClientData, setEditedClientData] = useState({}); // Dados do Cliente em edição
    const [currentPage, setCurrentPage] = useState(1);
    const affiliatesPerPage = 10;

    const navigate = useNavigate();
    const url = process.env.REACT_APP_BASE_URL
    const user = JSON.parse(localStorage.getItem('user'));
    const userRef = user.data.email

    const showDeleteConfirmation = (affiliateId) => {
        setIsDeleteConfirmationVisible(true);
        setClientToDelete(affiliateId);
    };
    const handleDeleteClient = (affiliateId) => {
        setConfirmationActive(true);
        axios.delete(`${url}/master/${affiliateId}`)
            .then((response) => {
                if (response.status === 204) {
                    setDeleteMessage('Cliente Excluído com sucesso!');
                    setDeleteSuccess(true);
                    setIsDeleteConfirmationVisible(false);

                    // Remova o Cliente excluído da lista
                    setClients((prevClients) => prevClients.filter((affiliate) => affiliate._id !== affiliateId));

                    // Configurar um timeout para ocultar a mensagem após 5 segundos
                    setTimeout(() => {
                        setDeleteMessage('');
                        setDeleteError(false);
                    }, 5000); // 5000 milissegundos (5 segundos)
                }
            })
            .catch((error) => {
                setDeleteMessage('Erro interno do servidor.');
                setDeleteError(true);
                console.error(error);

                // Configurar um timeout para ocultar a mensagem de erro após 5 segundos
                setTimeout(() => {
                    setDeleteMessage('');
                    setDeleteError(false);
                }, 5000); // 5000 milissegundos (5 segundos)
            })
            .finally(() => {
                setConfirmationActive(false);
            });
    };

    const handleEditClient = (affiliateId) => {
        setEditingClientId(affiliateId);
        const affiliateToEdit = affiliates.find((affiliate) => affiliate._id === affiliateId);
        setEditedClientData({ razaoSocial: affiliateToEdit.razaoSocial, email: affiliateToEdit.email, password: "", cnpj: affiliateToEdit.cnpj });
    };

    const handleCancelEdit = () => {
        setEditingClientId(null);
        setEditedClientData({});
    };

    const handleUpdateClient = async () => {
        try {
            const response = await axios.put(`${url}/master?userRef=${userRef}`, {requestBody: editedClientData});
            if (response.status === 200) {
                window.alert('Cliente atualizado com sucesso!');
                setEditingClientId(null);
                setEditedClientData({});
                handleClients();
            }
        } catch (error) {
            window.alert('Erro ao atualizar o afiliado!');
        }
    };

    const handleClients = async () => {
        try {
            const response = await axios.get(`${url}/master?userRef=${userRef}`);
            console.log(response.data)
            setClients(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        handleClients();
    }, []);

    // Função para renderizar afiliadoes com base na página atual
    const renderClients = () => {
        const startIndex = (currentPage - 1) * affiliatesPerPage;
        const endIndex = startIndex + affiliatesPerPage;
        return affiliates.slice(startIndex, endIndex).map((affiliate) => (
            <tr key={affiliate.id}>
                <td style={{ verticalAlign: "middle", cursor: 'pointer'}}>
                    {affiliate.origemRazaoSocial}
                </td>
                <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>{formatCnpj(affiliate.cnpj)}</td>
                {editingClientId === affiliate._id ? (
                    <>
                        <td style={{ verticalAlign: "middle" }}>
                            <input
                                className="inputsInicio"
                                type="text"
                                value={editedClientData.razaoSocial || ""}
                                onChange={(e) => setEditedClientData({ ...editedClientData, razaoSocial: e.target.value })}
                            />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                            <input
                                className="inputsInicio"
                                type="text"
                                value={editedClientData.email || ""}
                                disabled
                            />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                            <input
                                className="inputsInicio"
                                type="password"
                                placeholder="Mantenha em Branco"
                                value={editedClientData.password || ""}
                                onChange={(e) => setEditedClientData({ ...editedClientData, password: e.target.value })}
                            />
                        </td>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>
                            <button type="button" onClick={() => handleUpdateClient(affiliate._id)}>Salvar</button>
                            <button type="button" onClick={handleCancelEdit}>Cancelar</button>
                        </td>
                    </>
                ) : (
                    <>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>{affiliate.razaoSocial}</td>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>{affiliate.email}</td>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>
                            <RiEdit2Line size={25} color="#92c01e" style={{ color: "#92c01e" }} onClick={() => handleEditClient(affiliate._id)} />
                        </td>
                        <td>
                            {isDeleteConfirmationVisible && affiliateToDelete === affiliate._id ? (
                                <div>
                                    <p>Tem certeza de que deseja excluir este afiliado?</p>
                                    <button type="button" className="affiliateButton" onClick={() => handleDeleteClient(affiliate._id)}>Sim</button>
                                    <button type="button" className="affiliateButton" onClick={() => setIsDeleteConfirmationVisible(false)}>Cancelar</button>
                                </div>
                            ) : (
                                <div onClick={() => showDeleteConfirmation(affiliate._id)} style={{ verticalAlign: "middle", cursor: 'pointer', justifyContent: 'center' }}>
                                    <FaTrashCan style={{ cursor: "pointer", color: "red" }} />
                                </div>
                            )}
                        </td>
                    </>
                )}
            </tr>
        ));
    };

    const totalPages = Math.ceil(affiliates.length / affiliatesPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <body className="inicioBody">
            <div className="fixed">
                <Header selected={"affiliate"} />
            </div>
            <main className="inicioMain">
                <div className="ahead">
                    <h2 className="formH2" style={{ textAlign: "center", marginLeft: '10rem', paddingLeft: 0 }}>
                        Gerenciar Clientes
                    </h2>
                    <div className="div2">
                        <h3>Bem Vindo, {user.data.razaoSocial}</h3>
                    </div>
                </div>


                <div onClick={() => navigate("/addClient")} className="buttonAddCola">
                    <div className="buttonAddCol">
                        Adicionar Cliente
                    </div>
                </div>
                <form
                    className="formInicio"
                >
                    <table
                        className="tableClients"
                    >
                        <thead className="headClients">
                            <tr>
                                <th>Origem</th>
                                <th>CNPJ</th>
                                <th>Razão Social</th>
                                <th>Email</th>
                                <th>{editingClientId ? "Senha" : ""}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="bodyClients">
                            {affiliates.length === 0 && (
                                <tr>
                                    <td colSpan={5} style={{ textAlign: "center" }}>
                                        <div style={{ textAlign: "center", marginBottom: "2rem", marginTop: "2rem" }}>Nenhum Cliente encontrado</div>
                                    </td>
                                </tr>
                            )}
                            {renderClients()}
                        </tbody>

                    </table>
                    {affiliates.length > affiliatesPerPage && (
                        <div className="button22">
                            <div className="buttonAddCol" onClick={prevPage} disabled={currentPage === 1}>
                                Anterior
                            </div>
                            <div className="buttonAddCol" onClick={nextPage} disabled={currentPage === totalPages}>
                                Próxima
                            </div>
                        </div>
                    )}
                    {deleteMessage && (
                        <div className={deleteError ? "errorMessage" : "successMessage"}>{deleteMessage}</div>
                    )}

                </form>
            </main>
        </body >
    );
};

export default Client;
