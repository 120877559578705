import React from 'react';
import { useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Link,
  Image,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import capaImage from '../../images/capa_proposta_trustree.jpg';
import logo from '../../images/trustee.jpg';
import logoParceiro from '../../images/logo_parceiro.png';


const styles = StyleSheet.create({
  // ... (seu estilo existente)

  coverPage: {
    position: 'relative',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch', // Ajusta o tamanho da imagem para cobrir toda a altura da página
    justifyContent: 'stretch', // Ajusta o tamanho da imagem para cobrir toda a largura da página
  },

  coverImage: {
    width: '100%',
    height: '100vh',
  },
  headerImage: {
    width: '25%',
    height:'22',
    paddingTop: '10'
  },
  logoParceiro: {
    width: '10%',
    height:'65',
    paddingTop: '10'
  },
  coverOverlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%', // Usando os 20% restantes para o texto
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adicione um fundo escuro transparente
    boxSizing: 'border-box',
  },
  coverTextContainer: {
    position: 'absolute',
    bottom: 50,
    width: '70%',
    height: '25%', // Usando os 20% restantes para o texto
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adicione um fundo escuro transparente
    boxSizing: 'border-box',
  },

  coverText: {
    color: 'white',
    fontSize: 17,
    textAlign: 'center',
    paddingTop: 20,
  },
  titlePage:{
    textAlign: 'center',
  },
  titlePage_2:{
    fontSize: 25,
    textAlign: 'center',
    marginBottom: 30,
    color: '#646464',
  },
  coverText4: {
    color: '#cccccc',
    fontSize: 17,
    paddingTop: 20,
  },
  coverText2: {
    paddingTop: 10,
    left:20,
    width:'90%',
    right:20,
    borderBottomWidth: 2,
    borderBottomColor: '#68472c', // Adicione esta linha para a cor da borda
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 20,
    width:'100%'
  },

  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  
  /*tabela*/
  carac_emissao:{
    paddingLeft:40,
    paddingRight:60,
    marginTop: '80px',
    marginBottom: '80px',
  },
  carac_emissao_item:{
    borderColor: '#c6c6c6', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderLeft: 2, // Borda no lado esquerdo
    borderTop: 2,   // Borda na parte superior
    width:'30%',
    padding: 5,
  },
  carac_emissao_item_last:{
    borderColor: 'black', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderLeft: 2, // Borda no lado esquerdo
    borderBottom: 2,   // Borda na parte superior
    borderTop: 2,
    width:'30%',
    padding: 5
  },
  carac_emissao_item_last_2:{
    borderColor: 'black', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderBottom: 2,   // Borda na parte superior
    borderTop: 2,
    width:'70%',
    padding: 5
  },
  carac_emissao_item_2:{
    borderColor: '#4f4f4f', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderTop: 2,   // Borda na parte superior
    width:'70%',
    padding: 5
  },
  carac_emissao_line:{
    flexDirection: 'row', // Coloca os elementos lado a lado
    justifyContent: 'space-between', // Distribui espaço entre os elementos
  },
  carac_emissao_line_2:{
    flexDirection: 'row', // Coloca os elementos lado a lado
    justifyContent: 'space-between', // Distribui espaço entre os elementos
    backgroundColor:'#cccccc',
  },
  footer:{
    height:'50px',
    backgroundColor:'#262626',
    textAlign: 'center',
    color: 'white',
    padding: 10,
    fontSize: 14,
    bottom:3,
    position:'absolute',
    width:'100%',
  },
  data_proposta:{
    fontSize: 13,
    marginLeft: 'auto',
    paddingRight: 10,
  },
  contatos_proposta:{
    fontSize: 13,
    marginLeft: 'auto',
    paddingRight: 10,
    marginTop:50,
  },
  container: {
    padding: 10,
    marginLeft:30,
    marginRight: 30,
    color:'#636363'
  },
  listItem: {
    marginBottom: 10,
  },
  borderBottom_tile: {
    borderBottom: 1,
    borderColor: '#646464',
    width: '100%',
    marginBottom: 10,
  },

  subtitulo:{
    fontWeight: 'bold', // Adicionando negrito
    marginBottom: 10,
    borderBottom: 2,
    width:'42%',
    color:'#464646',
    borderColor: '#464646', // Cor da borda
  },
  subtitulo_2:{
    color: '#323232', // Cor da borda
  }
   
});

const MyPDFViewer = ({ onClose ,dadosProposta }) => {
    //const coverImage = capaImage;
    //const logo = logo;
    const dataAtual = new Date();
    const allInfoArray1 = JSON.parse(localStorage.getItem('allInfoArray'));
    const allInfoArray2 = useSelector((state) => state.finalTotal.finalTotal)
    const allInfoArray = allInfoArray1 || allInfoArray2
    const anoAtual = dataAtual.getFullYear();
    const storedData = JSON.parse(localStorage.getItem('formValues'));
    const [parcelas, setParcelas] = useState(storedData ? storedData.parcelas : '');
    const [jurosAA, setJurosAA] = useState(storedData ? storedData.jurosAA : '');
    const paisagemWidth = 11; // polegadas
    const paisagemHeight = 7.5; // polegadas
    const getCurrentDate = () => {
    const now = new Date();
        return `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear()}`;
    };
   // const [jurosAA, setJurosAA] = useState('');

return (
    <PDFViewer width={`${paisagemWidth  * 100}px`} height={`${paisagemHeight * 100}px`} style={{}}>
      <Document title={`Nota Comercial - ${dadosProposta.NumeroProposta}`}>
        {/* Capa */}
        <Page size="A4"  orientation="landscape" style={styles.page}>
            <View style={styles.coverPage}>
                <Image src={capaImage} style={styles.coverImage} />
            </View>
            <View style={styles.coverOverlayContainer}>
            </View>
            <View style={styles.coverTextContainer}>
                <Text style={styles.coverText}>TRUSTEE DTVM</Text >
                <Text style={styles.coverText}>PROPOSTA COMERCIAL N° {`${dadosProposta.NumeroProposta}`}/{`${anoAtual}`}</Text >
                <Text style={styles.coverText2}></Text >
                <Text style={styles.coverText}>A/C :{`${dadosProposta.Solicitante}`}</Text >
            </View>
        </Page>
        {/* Páginas com cabeçalho e rodapé */}
          <Page  size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.header}>
                <Image src={logo} style={styles.headerImage} />
                <Image src={logoParceiro} style={styles.logoParceiro}/>
            </View >
            <View style={styles.titlePage_2}>
                <Text style={styles.coverTex4}>CARACTERÍSTICAS EMISSÃO</Text >
            </View>
            <View style={styles.carac_emissao}>
                <View style={styles.carac_emissao_line_2}>
                    <Text style={styles.carac_emissao_item}>Tipo de Estrutura</Text >
                    <Text style={styles.carac_emissao_item_2}>Nota Comercial Privada</Text>
                </View>
                <View style={styles.carac_emissao_line}>
                   <Text style={styles.carac_emissao_item}>Emissor</Text >
                   <Text style={styles.carac_emissao_item_2}>{`${dadosProposta.Emissor}`}</Text>
                </View>
                <View style={styles.carac_emissao_line}>
                    <Text style={styles.carac_emissao_item}>Volume</Text >
                    <Text style={styles.carac_emissao_item_2}>{`${allInfoArray[5] || '0,00'}`}</Text >
                </View>
                <View style={styles.carac_emissao_line_2}>
                    <Text style={styles.carac_emissao_item}>Séries</Text >
                    <Text style={styles.carac_emissao_item_2}>{`${dadosProposta.Serie}`}</Text >
                </View>
                <View style={styles.carac_emissao_line}>
                    <Text style={styles.carac_emissao_item}>Prazo</Text >
                    <Text style={styles.carac_emissao_item_2}>{`${parcelas}`} meses</Text >
                </View>
                <View style={styles.carac_emissao_line_2}>
                    <Text style={styles.carac_emissao_item}>Remuneração</Text >
                    <Text style={styles.carac_emissao_item_2}>{`${jurosAA} % a.a`|| '-'}</Text >
                </View>
                <View style={styles.carac_emissao_line}>
                    <Text style={styles.carac_emissao_item_last}>Amortização</Text >
                    <Text style={styles.carac_emissao_item_last_2}>{`${dadosProposta.Amortizacao}`}</Text >
                </View>
            </View>
            <Text style={styles.data_proposta} >Data da Proposta {getCurrentDate()}</Text>
            <View style={styles.footer}>
                <View>
                    <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                    <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                </View>
            </View >
          </Page>
           {/* Pag 2 */}
          <Page  size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.header}>
                <Image src={logo} style={styles.headerImage} />
                <Image src={logoParceiro} style={styles.logoParceiro}/>
            </View >
            <View style={styles.container}>
                <View style={styles.titlePage_2}>
                    <Text style={styles.coverTex4}>ESCOPO DE TRABALHO</Text >
                </View>
                <View>
                    <Text style={styles.borderBottom_tile}></Text >
                </View>
                <View>
                    <Text style={{ paddingBottom: 15,paddingTop:15 }}>A presente Proposta tem por escopo a prestação do serviço de Escrituração pela TRUSTEE DTVM na Oferta com as características acima informadas.</Text >
                </View>
                <View>
                    <Text style={styles.subtitulo}>Descrição dos Serviços de Escrituração</Text >
                </View>
                <View>
                    <Text style={{ paddingBottom: 15 }}> Os serviços a serem prestados pela Trustee DTVM contemplam as seguintes atividades</Text >
                </View>
                <View style={styles.listItem}>
                    <Text>(i) a abertura e manutenção, em sistemas informatizados de livro de registro, conforme previsto na regulamentação em vigor;</Text>
                </View>
                <View style={styles.listItem}>
                    <Text>(ii) o registro das informações relativas à titularidade das notas comerciais, assim como de direitos reais de fruição ou de garantia e de outros gravames incidentes sobre elas;</Text>
                </View>
                <View style={styles.listItem}>
                    <Text>(iii) o tratamento das instruções de movimentações recebidas do Emissor ou de pessoas legitimadas por contrato ou mandato, conforme disposto no contrato a ser firmado entre as partes;</Text>
                </View>
            </View>
            <View style={styles.footer}>
                <View>
                    <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                    <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                </View>
            </View >
          </Page>
           {/* Pag 3 */}
          <Page  size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.header}>
                <Image src={logo} style={styles.headerImage} />
                <Image src={logoParceiro} style={styles.logoParceiro}/>
            </View >
            <View style={styles.container}>
                <View style={styles.titlePage_2}>
                    <Text style={styles.coverTex4}>ESCOPO DE TRABALHO</Text >
                </View>
                <View>
                    <Text style={styles.borderBottom_tile}></Text >
                </View>
                <View style={styles.listItem}>
                    <Text>(iv) a realização dos procedimentos e registros necessários à efetivação e à aplicação às notas comerciais, quando for o caso, do regime de depósito centralizado; e </Text>
                </View>
                <View style={styles.listItem}>
                    <Text>(v) o tratamento dos eventos incidentes sobre as notas comerciais.</Text>
                </View>
            </View>
            <View style={styles.footer}>
                <View>
                    <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                    <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                </View>
            </View >
          </Page>
          {/* Pag 4 */}
          <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.headerImage} />
                    <Image src={logoParceiro} style={styles.logoParceiro}/>
                </View >
                <View style={styles.container}>
                    <View style={styles.titlePage_2}>
                        <Text style={styles.coverTex4}>NOSSA TRAJETÓRIA</Text >
                    </View>
                    <View>
                        <Text style={styles.borderBottom_tile}></Text >
                    </View>
                    <View >
                        <Text style={{ paddingTop: 15 }} >A <Text style={styles.subtitulo_2} >Trustee DVM</Text> foi constituída em 1991 e autorizada a funcionar pelo Banco Central do Brasil como instituição financeira (“<Text style={styles.subtitulo_2} >Trustee DTVM</Text>”).</Text>
                    </View>
                    <View >
                        <Text style={{ paddingTop: 15 }} >De 1999 até o início de 2020 a <Text style={styles.subtitulo_2} >Trustee DTVM</Text> fez parte do Grupo Planner, quando foi iniciado o processo de reorganização societária concluído em julho de 2022 e, seu controle passou a ser exercido isoladamente por Maurício Quadrado.</Text>
                    </View>
                    <View >
                        <Text style={{ paddingTop: 15 }} >Desde 2020 a <Text style={styles.subtitulo_2} >Trustee DTVM</Text> está numa nova sede e conta com novos executivos em sua diretoria.</Text>
                    </View>
                    <View >
                        <Text style={{ paddingTop: 15 }} >Seus segmentos de atuação são: Agente Fiduciário, Administração Fiduciária, Gestão de Recursos e Serviços Qualificados.</Text>
                    </View>
                    <View >
                        <Text style={{ paddingTop: 15 }} >As equipes foram integralmente mantidas nessa nova estrutura e seu time conta com toda expertise necessária para o exercido das funções e atendimento ao mercado.
                    </Text>
                    </View>
                </View>
                <View style={styles.footer}>
                    <View>
                        <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                        <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                    </View>
                </View >
          </Page>
           {/* Pag 5 */}
           <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.headerImage} />
                    <Image src={logoParceiro} style={styles.logoParceiro}/>
                </View >
                <View style={styles.container}>
                    <View style={styles.titlePage_2}>
                        <Text style={styles.coverTex4}>DIFERENCIAIS NA ESCOLHA DA TRUSTEE DTVM</Text >
                    </View>
                    <View>
                        <Text style={styles.borderBottom_tile}></Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>• Forte capacidade operacional no desenvolvimento das funções fiduciárias, atendendo as demandas do mercado em diversas modalidades de operações estruturadas;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>• Solidez, estabilidade e integridade de nossa marca não só atraem e mantêm os colaboradores mais capacitados, como também garantem o mais alto nível de qualidade dos serviços;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>• Situada no coração do mercado financeiro de São Paulo, com instalações de design moderno e reunindo os melhores recursos tecnológicos disponíveis com todo o aparato operacional necessário para a realização de reuniões e assembleias presenciais e on-line;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>• Expertise na condução de operações que encontram-se em situações de estresse;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>• Agilidade no atendimento às necessidades dos Emissores e seus investidores; e</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>• Compromisso em oferecer um serviço personalizado da mais alta qualidade e eficiência, assegurando um dever de agir com celeridade e eficácia para proteção dos interesses daqueles que representamos.um dever de agir com celeridade e eficácia para proteção dos interesses daqueles que representamos</Text >
                    </View>
                </View>
                <View style={styles.footer}>
                    <View>
                        <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                        <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                    </View>
                </View >
          </Page>
           {/* Pag 5 */}
           <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.headerImage} />
                    <Image src={logoParceiro} style={styles.logoParceiro}/>
                </View >
                <View style={styles.container}>
                    <View style={styles.titlePage_2}>
                        <Text style={styles.coverTex4}>REMUNERAÇÃO</Text >
                    </View>
                    <View>
                        <Text style={styles.borderBottom_tile}></Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>1. Para os serviços de Escrituração das Notas Comerciais, será devida uma única parcela no valor de {`${allInfoArray[2] || '0,00'}`} a ser paga na data de emissão do título;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>2. O pagamento das parcelas de remuneração descritas no item 1 acima deverá ser feito à Trustee DTVM, acrescido dos valores relativos aos impostos e incidentes sobre o faturamento: ISSQN (Imposto sobre serviços de qualquer natureza), PIS (Contribuição ao Programa de Integração Social), e COFINS (Contribuição Social para o Financiamento da Seguridade Social) incluindo quaisquer majorações das alíquotas já existentes, de forma que a Trustee receba a remuneração como se tais tributos não fossem incidentes;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>3. O Emissor somente incidirá o pagamento da remuneração após o início da prestação dos serviços pela Trustee DTVM;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>4. Em caso de mora no pagamento de qualquer quantia devida em decorrência da remuneração ora proposta, os débitos em atraso ficarão sujeitos a juros de mora de 1% (um por cento) ao mês e multa não compensatória de 2% sobre o valor devido;</Text >
                    </View>
                </View>
                <View style={styles.footer}>
                    <View>
                        <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                        <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                    </View>
                </View >
          </Page>
          {/* Pag 6 */}
          <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.headerImage} />
                    <Image src={logoParceiro} style={styles.logoParceiro}/>
                </View >
                <View style={styles.container}>
                    <View style={styles.titlePage_2}>
                        <Text style={styles.coverTex4}>REMUNERAÇÃO</Text >
                    </View>
                    <View>
                        <Text style={styles.borderBottom_tile}></Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>5. As parcelas referidas acima serão atualizadas, anualmente, de acordo com a variação positiva acumulada do IGP-M, ou na sua falta ou impossibilidade de aplicação, pelo índice oficial que vier a substituí-lo, a partir da data do pagamento da primeira parcela, até as datas de pagamento de cada parcela subsequente calculada “pro rata temporis”.</Text >
                    </View>
                </View>
                <View style={styles.footer}>
                    <View>
                        <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                        <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                    </View>
                </View >
          </Page>
            {/* Pag 7 */}
            <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.headerImage} />
                    <Image src={logoParceiro} style={styles.logoParceiro}/>
                </View >
                <View style={styles.container}>
                    <View style={styles.titlePage_2}>
                        <Text style={styles.coverTex4}>VALIDADE E CONDIÇÃO PRECEDENTE</Text >
                    </View>
                    <View>
                        <Text style={styles.borderBottom_tile}></Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>Esta proposta é indicativa e terá validade de 30 (trinta) dias a contar da data da sua emissão, e a concordância dos termos aqui apresentados dar-se-á com a(s) assinatura(s) pelo(s) representante(s) legal(is) dessa empresa ao final indicado.</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>No caso de eventuais obrigações adicionais à Trustee DTVM, ou ainda no caso de alteração das características da emissão que implique num maior grau de complexidade do exercício de suas funções, ficará facultado à Trustee DTVM a revisão dos honorários da presente proposta.</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>A contratação para os serviços ora propostos está condicionada à aprovação pelo Comitê interno da Trustee DTV</Text >
                    </View>
                </View>
                <View style={styles.footer}>
                    <View>
                        <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                        <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                    </View>
                </View >
          </Page>
            {/* Pag 8 */}
            <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.headerImage} />
                    <Image src={logoParceiro} style={styles.logoParceiro}/>
                </View >
                <View style={styles.container}>
                    <View style={styles.titlePage_2}>
                        <Text style={styles.coverTex4}>DOCUMENTOS</Text >
                    </View>
                    <View>
                        <Text style={styles.borderBottom_tile}>Para exercício da função de Escriturador, a Trustee DTVM solicita a disponibilização das informações abaixo:</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>Para exercício da função de Escriturador, a Trustee DTVM solicita a disponibilização das informações abaixo:</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>(i) Atos societários que deliberaram sobre a emissão e suas publicações registrado na Junta Comercial, se aplicável;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>(ii) Cópia autenticada do Estatuto Social do Emissor registrado na Junta Comercial;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>(iii) Cópia autenticada das atas de eleição da última diretoria e do último conselho de administração do Emissor registradas na Junta Comercial;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>(iv) Escritura ou Termo de emissão dos títulos registradas na Junta Comercial, se aplicável;</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>(v) Relação dos Investidores, com seus respectivos dados cadastrais completos, quantidades de títulos detidas;</Text >
                    </View>
                </View>
                <View style={styles.footer}>
                    <View>
                        <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                        <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                    </View>
                </View >
          </Page>
            {/* Pag 9 */}
            <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.headerImage} />
                    <Image src={logoParceiro} style={styles.logoParceiro}/>
                </View >
                <View style={styles.container}>
                    <View style={styles.titlePage_2}>
                        <Text style={styles.coverTex4}>DOCUMENTOS</Text >
                    </View>
                    <View>
                        <Text style={styles.borderBottom_tile}></Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>Para exercício da função de Escriturador, a Trustee DTVM solicita a disponibilização das informações abaixo:</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>Colocamo-nos à sua inteira disposição para quaisquer esclarecimentos complementares.</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>De acordo em ___ /___ /___</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>______________________________________</Text >
                    </View>
                    <View>
                        <Text style={{ paddingTop: 15 }}>Emissora</Text >
                    </View>
                    <View style={styles.contatos_proposta}>
                        <View>
                            <Text>Atenciosamente,</Text >
                        </View>
                        <View>
                            <Text style={styles.subtitulo_2} >TRUSTEE DTVM LTDA.</Text>
                        </View>
                        <View>
                            <Text>Estevam Borali</Text >
                        </View>
                        <View>
                            <Text>Linha direta: (11) 2197-4452</Text >
                        </View>
                        <View>
                            <Text>E-mail: eborali@trusteedtvm.com.br</Text >
                        </View>

                    </View>
                </View>
                <View style={styles.footer}>
                    <View>
                        <Link style={styles.link} src="https://www.trusteedtvm.com.br/" target="_blank">https://www.trusteedtvm.com.br/</Link>
                        <Text style={{ marginTop: 13 }} >Av. Brigadeiro Faria Lima, 3.477 - 11º andar – Torre A - Itaim Bibi - São Paulo, SP - CEP: 04538-133</Text >
                    </View>
                </View >
          </Page>
        
      </Document>
    </PDFViewer>
  );
};

export default MyPDFViewer;
