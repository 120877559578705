import React, { useState } from "react";
import "../../styles/ShowNotes.css";
import { MdSimCardDownload } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import moment from 'moment';

const ShowNotes = ({ note, onClose }) => {
    
    const keyTranslation = {
        urlMinuta: "Minuta",
        urlContrato: "Contrato",
        urlCondicoes: "Condições",
        urlFaturamento: "Relatório de Faturamento",
        urlGarantias: "Garantias",
        urlDocumentos: "Documentos",
    };

  
    const dataUTC = note['createdAt']
    const dataBrasil = moment(dataUTC).format('DD/MM/YYYY HH:mm:ss');

    const urlKeys = Object.keys(note).filter((key) => key.startsWith("url"));
    const nonUrlKeys = Object.keys(note).filter((key) => !key.startsWith("url"));
        // console.log(nonUrlKeys,'nonUrlKeys')
    const [currentPage, setCurrentPage] = useState(1);

    const nextPage = () => {
        setCurrentPage(2);
    };

    const previousPage = () => {
        setCurrentPage(1);
    };

    return (
        <div className="showNotes">
            <div className="showNotes-content">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                    
                }}>
                    <h2 className="modalH2">Detalhes da Nota</h2>
                    <div className="close-button2" onClick={onClose}>&times;</div>
                </div>
                {/*{currentPage === 1 && ( */}
                    <table className="table">
                        <tbody>
                                <tr className="table-row">
                                    <td className="table-cell table-key">
                                      Origem da Nota:
                                    </td>
                                    <td className="table-cell table-value">{note['origemRazaoSocial']}</td>
                                   
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell table-key">
                                      Escrituradora:
                                    </td>
                                    <td className="table-cell table-value">{note['escrituradora']}</td>
                                   
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell table-key">
                                      Data de Emissão:
                                    </td>
                                    <td className="table-cell table-value">{dataBrasil}</td>
                                    {/*<AiFillCheckCircle style={{ color: "green", height: "40px", width: "40px" }} />*/}
                                </tr>
                        </tbody>
                    </table>
               {/*   )} */}
                {/*  {currentPage === 2 && (
                        <table className="table">
                            <tbody>
                                {urlKeys.map((key) => (
                                    <tr key={key} className="table-row">
                                        <td className="table-cell table-key">
                                            Baixar {keyTranslation[key] ? keyTranslation[key] : key}:
                                        </td>
                                        <td className="table-cell table-value">
                                            <a href={note[key]} target="_blank" rel="noopener noreferrer">
                                                <MdSimCardDownload className="iconEmitted" size={20} />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <div className="pagination">
                        {currentPage === 1 && (
                            <button onClick={nextPage} className="page-button">
                                Próxima Página
                            </button>
                        )}
                        {currentPage === 2 && (
                            <button onClick={previousPage} className="page-button">
                                Página Anterior
                            </button>
                        )}
                        </div>*/
                }
            </div>
        </div>
    );
};

export default ShowNotes;
