import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { FaTrashCan } from "react-icons/fa6";
import { RiEdit2Line } from "react-icons/ri";
import axios from "axios";
import '../styles/Inicio.css'
import { formatCpf } from "../utils/Tools";

const Inicio = () => {
    const [employees, setEmployees] = useState([]);
    const [confirmationActive, setConfirmationActive] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [employeeToDelete, setEmployeeToDelete] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState(""); // Para exibir mensagens de sucesso ou erro
    const [deleteError, setDeleteError] = useState(false); // Para indicar erros na exclusão
    const [editingEmployeeId, setEditingEmployeeId] = useState(null); // ID do colaborador em edição
    const [editedEmployeeData, setEditedEmployeeData] = useState({}); // Dados do colaborador em edição
    const [currentPage, setCurrentPage] = useState(1);
    const employeesPerPage = 10;

    const navigate = useNavigate();
    const url = process.env.REACT_APP_BASE_URL
    const user = JSON.parse(localStorage.getItem('user'));
    const userRef = user.data.email

    const showDeleteConfirmation = (employeeId) => {
        setIsDeleteConfirmationVisible(true);
        setEmployeeToDelete(employeeId);
    };
    const handleDeleteEmployee = (employeeId) => {
        setConfirmationActive(true);
        axios.delete(`${url}/employee/${employeeId}`)
            .then((response) => {
                if (response.status === 204) {
                    setDeleteMessage('Colaborador Excluído com sucesso!');
                    setDeleteSuccess(true);
                    setIsDeleteConfirmationVisible(false);

                    // Remova o colaborador excluído da lista
                    setEmployees((prevEmployees) => prevEmployees.filter((employee) => employee._id !== employeeId));

                    // Configurar um timeout para ocultar a mensagem após 5 segundos
                    setTimeout(() => {
                        setDeleteMessage('');
                        setDeleteError(false);
                    }, 5000); // 5000 milissegundos (5 segundos)
                }
            })
            .catch((error) => {
                setDeleteMessage('Erro interno do servidor.');
                setDeleteError(true);
                console.error(error);

                // Configurar um timeout para ocultar a mensagem de erro após 5 segundos
                setTimeout(() => {
                    setDeleteMessage('');
                    setDeleteError(false);
                }, 5000); // 5000 milissegundos (5 segundos)
            })
            .finally(() => {
                setConfirmationActive(false);
            });
    };

    const handleEditEmployee = (employeeId) => {
        setEditingEmployeeId(employeeId);
        const employeeToEdit = employees.find((employee) => employee._id === employeeId);
        setEditedEmployeeData({ nome: employeeToEdit.nome, email: employeeToEdit.email, senha: "", cpf: employeeToEdit.cpf });
        console.log(employeeToEdit, 'employeeToEdit');
    };

    const handleCancelEdit = () => {
        setEditingEmployeeId(null);
        setEditedEmployeeData({});
    };

    const handleUpdateEmployee = async () => {
        try {
            const response = await axios.put(`${url}/employee/`, editedEmployeeData);
            if (response.status === 200) {
                window.alert('Colaborador atualizado com sucesso!');
                setEditingEmployeeId(null);
                setEditedEmployeeData({});
                handleEmployees();
            }
        } catch (error) {
            window.alert('Erro ao atualizar o colaborador!');
            console.error(error);
        }
    };

    const handleEmployees = async () => {
        try {
            const response = await axios.get(`${url}/employee?userRef=${userRef}`);
            console.log(response.data)
            setEmployees(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        handleEmployees();
    }, []);

    // Função para renderizar colaboradores com base na página atual
    const renderEmployees = () => {
        const startIndex = (currentPage - 1) * employeesPerPage;
        const endIndex = startIndex + employeesPerPage;
        return employees.slice(startIndex, endIndex).map((employee) => (
            <tr key={employee.id}>
                <td style={{ verticalAlign: "middle", cursor: 'pointer'}}>
                    {employee.origemRazaoSocial || "-"}
                </td>
                <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>{formatCpf(employee.cpf)}</td>
                {editingEmployeeId === employee._id ? (
                    <>
                        <td style={{ verticalAlign: "middle" }}>
                            <input
                                className="inputsInicio"
                                type="text"
                                value={editedEmployeeData.nome || ""}
                                onChange={(e) => setEditedEmployeeData({ ...editedEmployeeData, nome: e.target.value })}
                            />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                            <input
                                className="inputsInicio"
                                type="text"
                                value={editedEmployeeData.email || ""}
                                onChange={(e) => setEditedEmployeeData({ ...editedEmployeeData, email: e.target.value })} 
                                disabled
                            />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                            <input
                                className="inputsInicio"
                                type="password"
                                placeholder="Mantenha em Branco"
                                value={editedEmployeeData.password || ""}
                                onChange={(e) => setEditedEmployeeData({ ...editedEmployeeData, password: e.target.value })}
                            />
                        </td>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>
                            <button type="button" onClick={() => handleUpdateEmployee(employee._id)}>Salvar</button>
                            <button type="button" onClick={handleCancelEdit}>Cancelar</button>
                        </td>
                    </>
                ) : (
                    <>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>{employee.nome}</td>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>{employee.email}</td>
                        <td style={{ verticalAlign: "middle", cursor: 'pointer' }}>
                            <RiEdit2Line size={25} color="#92c01e" style={{ color: "#92c01e" }} onClick={() => handleEditEmployee(employee._id)} />
                        </td>
                        <td>
                            {isDeleteConfirmationVisible && employeeToDelete === employee._id ? (
                                <div>
                                    <p>Tem certeza de que deseja excluir este colaborador?</p>
                                    <button type="button" className="employeeButton" onClick={() => handleDeleteEmployee(employee._id)}>Sim</button>
                                    <button type="button" className="employeeButton" onClick={() => setIsDeleteConfirmationVisible(false)}>Cancelar</button>
                                </div>
                            ) : (
                                <div onClick={() => showDeleteConfirmation(employee._id)} style={{ verticalAlign: "middle", cursor: 'pointer', justifyContent: 'center' }}>
                                    <FaTrashCan style={{ cursor: "pointer", color: "red" }} />
                                </div>
                            )}
                        </td>
                    </>
                )}
            </tr>
        ));
    };

    const totalPages = Math.ceil(employees.length / employeesPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <body className="inicioBody">
            <div className="fixed">
                <Header selected={"master"} />
            </div>
            <main className="inicioMain">
                <div className="ahead">
                    <h2 className="formH2" style={{ textAlign: "center", marginLeft: '10rem', paddingLeft: 0 }}>
                        Gerenciar usuários
                    </h2>
                    <div className="div2">
                        <h3>Bem Vindo, {user.data.razaoSocial}</h3>
                    </div>
                </div>


                <div onClick={() => navigate("/addEmployee")} className="buttonAddCola">
                    <div className="buttonAddCol">
                        Adicionar Colaborador
                    </div>
                </div>
                <form
                    className="formInicio"
                >
                    <table
                        className="tableEmployees"
                    >
                        <thead className="headEmployees">
                            <tr>
                                <th>Origem</th>
                                <th>CPF</th>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>{editingEmployeeId ? "Senha" : ""}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="bodyEmployees">
                            {employees.length === 0 && (
                                <tr>
                                    <td colSpan={5} style={{ textAlign: "center" }}>
                                        <div style={{ textAlign: "center", marginBottom: "2rem", marginTop: "2rem" }}>Nenhum colaborador encontrado</div>
                                    </td>
                                </tr>
                            )}
                            {renderEmployees()}
                        </tbody>

                    </table>
                    {employees.length > employeesPerPage && (
                        <div className="button22">
                            <div className="buttonAddCol" onClick={prevPage} disabled={currentPage === 1}>
                                Anterior
                            </div>
                            <div className="buttonAddCol" onClick={nextPage} disabled={currentPage === totalPages}>
                                Próxima
                            </div>
                        </div>
                    )}
                    {deleteMessage && (
                        <div className={deleteError ? "errorMessage" : "successMessage"}>{deleteMessage}</div>
                    )}

                </form>
            </main>
        </body >
    );
};

export default Inicio;
