import axios from "axios";

const notaIt4 = () => {
    console.log('chamou')
    const url = 'http://localhost:6024/it4/nota/';
    console.log(url)

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    /*     const data = {
            Cnpj: dados.cnpj,
            Nome: dados.Nome,
            NomeAbreviado: dados.NomeAbreviado,
            Isin: dados.Isin || "BRBR6Q769LYK18",
            DataInicialCustodia: dados.DataInicialCustodia || "2023-06-05",
            DataAtualCustodia: dados.DataAtualCustodia || "2023-11-16",
            DataPrevistaVencimento: dados.DataPrevistaVencimento || "2025-12-14",
            SubTipo: dados.SubTipo || 192,
            Modalidade: dados.Modalidade || null,
            Contrato: dados.Contrato || "85601",
            DescricaoGarantia: dados.DescricaoGarantia || "Itaque et nobis molestiae exercitationem quidem alias. At laudantium aut tempore commodi. Ut sit veritatis ut sunt aut. Sit nihil nobis incidunt facere omnis et.",
            Dados: {
                QuantidadeEmitida: dados.Dados.QuantidadeEmitida || 310.0,
                PUEmissao: dados.Dados.puEmissao || 7.72,
                ValorEmissao: dados.Dados.ValorEmissao || 2393.20,
                DataVencimento: dados.Dados.DataVencimento || "2025-12-14",
                DataInicioRentabilidade: dados.Dados.DataInicioRentabilidade || "2023-11-10",
                TipoPagJuros: dados.Dados.TipoPagJuros || "bullet",
                TipoPagAmortizacao: dados.Dados.TipoPagAmortizacao || "anual",
                CodSerieIndexador: dados.Dados.CodSerieIndexador || 188,
                PercRemuneracao: dados.Dados.PercRemuneracao || 39.4523386561556000,
                TaxaPreMes: dados.Dados.TaxaPreMes || 0.593475,
                NumeroDiasTaxa: dados.Dados.NumeroDiasTaxa || 252,
                IndicesInflacao: dados.Dados.IndicesInflacao || null
            },
            Emissor: {
                Cnpj: dados.Emissor.Cnpj || 51977990000153,
                Dados: {
                    Nome: dados.Emissor.Dados.Nome || "Teste Nome Um",
                    Isin: dados.Emissor.Dados.Isin || "BR0LK7I39050",
                    Email: dados.Emissor.Dados.Email || "testenome@yahoo.com",
                    CVM: dados.Emissor.Dados.CVM || false,
                    Endereco: {
                        Cep: dados.Emissor.Dados.Endereco.Cep || 74777118,
                        Endereco: dados.Emissor.Dados.Endereco.Endereco || "Mariana Rodovia",
                        EnderecoNumero: dados.Emissor.Dados.Endereco.EnderecoNumero || "607",
                        EnderecoComplemento: dados.Emissor.Dados.Endereco.EnderecoComplemento || "29254689",
                        Bairro: dados.Emissor.Dados.Endereco.Bairro || "Gabriel Alameda",
                        Cidade: dados.Emissor.Dados.Endereco.Cidade || "Belo Horizonte",
                        UF: dados.Emissor.Dados.Endereco.UF || "PA"
                    }
                }
            },
            Administrador: {
                Cnpj: dados.Administrador.Cnpj || 51977990000153,
                Dados: {
                    PathLogotipoAdministrador: "/sbin/architect.hh",
                    PathImagemTextoCarta: "/Network/static_online_implement.install",
                    PathAssinaturaCarta: "/private/tmp/kids.psf",
                    RelPdfSenhaMaster: "USL51K4On2",
                    RelPdfPrefixoSenhaCliente: "69clWUDbXM",
                    RespDirfNome: "Caio Braga",
                    RespDirfCpf: "764.768.985-15",
                    RespDirfEmail: "CaioBraga_Carvalho@live.com",
                    RespDirfDDD: "57",
                    RespDirfTelefone: "63155843",
                    RespDirfRamal: null,
                    RespDirfFax: "92298004",
                    DirfNatureza: "5",
                    DirfCpfResponsavelCnpj: "764.768.985-15",
                    DirfRegimeFII: "1",
                    DirfIndicadorSocioOstensivo: true,
                    DirfIndicadorDeclaranteDepositario: true,
                    DirfIndicadorDeclaranteInstituicao: true,
                    DirfIndicadorDeclaranteRendPagExterior: true,
                    DirfIndicadorPlanoPrivado: true,
                    DirfIndicadorPagOlimpiadas: true,
                    DirfIndicadorUniaoDetemMaioria: true,
                    DirfIndicadorSituacaoEspecial: false,
                    Nome: "Silva e Associados",
                    Isin: "BR54R0X5B006",
                    Email: "SilvaeAssociados19@gmail.com",
                    CVM: null,
                    Endereco: {
                        Cep: 22192561,
                        Endereco: "Maria Alice Avenida",
                        EnderecoNumero: "787",
                        EnderecoComplemento: "1482176349",
                        Bairro: "Fabiano Rua",
                        Cidade: "São Bernardo do Campo",
                        UF: "MA"
                    }
                }
            },
            custodiante: {
                Cnpj: dados.custodiante.Cnpj,
                Dados: {
                    Nome: dados.custodiante.Dados.Nome,
                    Isin: "BREIYAM39130",
                    Email: dados.custodiante.Dados.Email,
                    CVM: true,
                    Endereco: {
                        Cep: dados.custodiante.Dados.Endereco.Cep,
                        Endereco: dados.custodiante.Dados.Endereco.Endereco,
                        EnderecoNumero: dados.custodiante.Dados.Endereco.EnderecoNumero,
                        EnderecoComplemento: dados.custodiante.Dados.Endereco.EnderecoComplemento,
                        Bairro: dados.custodiante.Dados.Endereco.Bairro,
                        Cidade: dados.custodiante.Dados.Endereco.Cidade,
                        UF: dados.custodiante.Dados.Endereco.UF
                    }
                }
            },
            liquidante: {
                Cnpj: 51977990000153,
                Dados: {
                    Nome: "Reis EIRELI",
                    Isin: "BR8Z7Y39KF11",
                    Email: "ReisEIRELI82@hotmail.com",
                    CVM: true,
                    Endereco: {
                        Cep: 12742045,
                        Endereco: "Murilo Alameda",
                        EnderecoNumero: "6543",
                        EnderecoComplemento: "1748099278",
                        Bairro: "Margarida Avenida",
                        Cidade: "Limeira",
                        UF: "TO"
                    }
                }
            },
            Investidores: dados.investidores || null,
            AgendaPagamentos: [
                {
                    Data: "2026-08-19",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 486.40
                },
                {
                    Data: "2023-12-10",
                    HasJuros: true,
                    HasAmortizacao: true,
                    ValorPrevisto: 165.73
                },
                {
                    Data: "2026-07-25",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 715.24
                },
                {
                    Data: "2026-05-15",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 750.36
                },
                {
                    Data: "2025-11-15",
                    HasJuros: true,
                    HasAmortizacao: true,
                    ValorPrevisto: 712.28
                },
                {
                    Data: "2026-07-11",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 768.06
                },
                {
                    Data: "2024-10-07",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 335.16
                },
                {
                    Data: "2026-06-25",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 589.96
                }
            ]
        }; */
    const data = {
        "Cnpj": 51977990000153,
        "Nome": "Teste Nome Um",
        "NomeAbreviado": "Teste",
        "Isin": "BRBR6Q769LYK18",
        "DataInicialCustodia": "2023-06-05",
        "DataAtualCustodia": "2023-11-16",
        "DataPrevistaVencimento": "2025-12-14",
        "SubTipo": 192,
        "Modalidade": null,
        "Contrato": "85601",
        "DescricaoGarantia": "Itaque et nobis molestiae exercitationem quidem alias. At laudantium aut tempore commodi. Ut sit veritatis ut sunt aut. Sit nihil nobis incidunt facere omnis et.",
        "Dados": {
            "QuantidadeEmitida": 310.0,
            "PUEmissao": 7.72,
            "ValorEmissao": 2393.20,
            "DataVencimento": "2025-12-14",
            "DataInicioRentabilidade": "2023-11-10",
            "TipoPagJuros": "bullet",
            "TipoPagAmortizacao": "anual",
            "CodSerieIndexador": 188,
            "PercRemuneracao": 39.4523386561556000,
            "TaxaPreMes": 0.593475,
            "NumeroDiasTaxa": 252,
            "IndicesInflacao": null
        },
        "Emissor": {
            "Cnpj": 51977990000153,
            "Dados": {
                "Nome": "Teste Nome Um",
                "Isin": "BR0LK7I39050",
                "Email": "testenome@yahoo.com",
                "CVM": false,
                "Endereco": {
                    "Cep": 74777118,
                    "Endereco": "Mariana Rodovia",
                    "EnderecoNumero": "607",
                    "EnderecoComplemento": "29254689",
                    "Bairro": "Gabriel Alameda",
                    "Cidade": "Belo Horizonte",
                    "UF": "PA"
                }
            }
        },
        "Administrador": {
            "Cnpj": 51977990000153,
            "Dados": {
                "PathLogotipoAdministrador": "/sbin/architect.hh",
                "PathImagemTextoCarta": "/Network/static_online_implement.install",
                "PathAssinaturaCarta": "/private/tmp/kids.psf",
                "RelPdfSenhaMaster": "USL51K4On2",
                "RelPdfPrefixoSenhaCliente": "69clWUDbXM",
                "RespDirfNome": "Caio Braga",
                "RespDirfCpf": "764.768.985-15",
                "RespDirfEmail": "CaioBraga_Carvalho@live.com",
                "RespDirfDDD": "57",
                "RespDirfTelefone": "63155843",
                "RespDirfRamal": null,
                "RespDirfFax": "92298004",
                "DirfNatureza": "5",
                "DirfCpfResponsavelCnpj": "764.768.985-15",
                "DirfRegimeFII": "1",
                "DirfIndicadorSocioOstensivo": true,
                "DirfIndicadorDeclaranteDepositario": true,
                "DirfIndicadorDeclaranteInstituicao": true,
                "DirfIndicadorDeclaranteRendPagExterior": true,
                "DirfIndicadorPlanoPrivado": true,
                "DirfIndicadorPagOlimpiadas": true,
                "DirfIndicadorUniaoDetemMaioria": true,
                "DirfIndicadorSituacaoEspecial": false,
                "Nome": "Silva e Associados",
                "Isin": "BR54R0X5B006",
                "Email": "SilvaeAssociados19@gmail.com",
                "CVM": null,
                "Endereco": {
                    "Cep": 22192561,
                    "Endereco": "Maria Alice Avenida",
                    "EnderecoNumero": "787",
                    "EnderecoComplemento": "1482176349",
                    "Bairro": "Fabiano Rua",
                    "Cidade": "São Bernardo do Campo",
                    "UF": "MA"
                }
            }
        },
        "Custodiante": {
            "Cnpj": 59148270000197,
            "Dados": {
                "Nome": "Teste Nome Um",
                "Isin": "BREIYAM39130",
                "Email": "CostaLTDA.Carvalho@yahoo.com",
                "CVM": true,
                "Endereco": {
                    "Cep": 94349243,
                    "Endereco": "Melo Travessa",
                    "EnderecoNumero": "032",
                    "EnderecoComplemento": "1072112178",
                    "Bairro": "Moraes Rodovia",
                    "Cidade": "Feira de Santana",
                    "UF": "PA"
                }
            }
        },
        "Liquidante": {
            "Cnpj": 51977990000153,
            "Dados": {
                "Nome": "Reis EIRELI",
                "Isin": "BR8Z7Y39KF11",
                "Email": "ReisEIRELI82@hotmail.com",
                "CVM": true,
                "Endereco": {
                    "Cep": 12742045,
                    "Endereco": "Murilo Alameda",
                    "EnderecoNumero": "6543",
                    "EnderecoComplemento": "1748099278",
                    "Bairro": "Margarida Avenida",
                    "Cidade": "Limeira",
                    "UF": "TO"
                }
            }
        },
        "Investidores": [
            62725784824
        ],
        "AgendaPagamentos": [
            {
                "Data": "2026-08-19",
                "HasJuros": true,
                "HasAmortizacao": false,
                "ValorPrevisto": 486.40
            },
            {
                "Data": "2023-12-10",
                "HasJuros": true,
                "HasAmortizacao": true,
                "ValorPrevisto": 165.73
            },
            {
                "Data": "2026-07-25",
                "HasJuros": true,
                "HasAmortizacao": false,
                "ValorPrevisto": 715.24
            },
            {
                "Data": "2026-05-15",
                "HasJuros": true,
                "HasAmortizacao": false,
                "ValorPrevisto": 750.36
            },
            {
                "Data": "2025-11-15",
                "HasJuros": true,
                "HasAmortizacao": true,
                "ValorPrevisto": 712.28
            },
            {
                "Data": "2026-07-11",
                "HasJuros": true,
                "HasAmortizacao": false,
                "ValorPrevisto": 768.06
            },
            {
                "Data": "2024-10-07",
                "HasJuros": true,
                "HasAmortizacao": false,
                "ValorPrevisto": 335.16
            },
            {
                "Data": "2026-06-25",
                "HasJuros": true,
                "HasAmortizacao": false,
                "ValorPrevisto": 589.96
            }
        ]
    };


    axios.post(url, data, { headers: headers })
        .then(response => {
            console.log('Resposta:', response.data);
            console.log('Token:', response.data.access_token);
        })
        .catch(error => {
            console.log('Erro:', error);
            console.error('Erro:', error.message);
        });
}


export default notaIt4;
