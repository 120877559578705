import { useDispatch, useSelector } from 'react-redux';
import { setFinalTotal, setCalcData, setSacData } from '../redux/actions';

export function useCalculoSac() {
    const dispatch = useDispatch();
    const dados = useSelector((state) => state.dados.dados);
    let user = dados.data?.nome ?? 'teste';

    async function calcularSac(
        total,
        taxa,
        parcelas,
        carencia,
        custoCapital,
        taxaEmissao,
        vencimentoDia,
        vencimentoMes,
        vencimentoAno,
        emissaoCCB,
        tac,
        alienacao,
        tipocarencia,
        assinaturaDigital
    ) {
        dispatch(setCalcData([]))
        const taxaCCB = parseFloat(emissaoCCB)
        const valorInicial = parseFloat(total);
        const taxaFloat = parseFloat(taxa.replace(',', '.'));
        let quantidadeParcelas, tipocarencia2 = tipocarencia;
        console.log(tipocarencia2,'tipocarencia2Sac');
        if (carencia > 0) {
            quantidadeParcelas = parseInt(parcelas - carencia);
        } else {
            quantidadeParcelas = parseInt(parcelas);
        }
        const quantidadeParcelasInfo = quantidadeParcelas;
        let carenciaIteracao = parseInt(carencia);
        let carenciaDefault = parseInt(carencia);
        const capital = parseFloat(custoCapital);
        const emissao = parseFloat(taxaEmissao);
        const tac1 = parseFloat(tac);
        const alienacao1 = parseFloat(alienacao);
        const custoAssinatura = parseFloat(assinaturaDigital);
        const ccb = valorInicial * taxaCCB / 100;
        const jurosMensais = taxaFloat / 100 ;
        const capitalTaxa = capital / 100;
        let valorI = valorInicial + emissao + tac1 + alienacao1 + custoAssinatura;
        let saldoDevedor = valorI;
        let inicial = valorInicial + emissao + tac1 + alienacao1 + custoAssinatura;

        const dataInicial = new Date(vencimentoAno, vencimentoMes, vencimentoDia);
        const diaInicial = dataInicial.getDate() + 1;
        const mesInicial = dataInicial.getMonth();
        const mesInicial1 = dataInicial.getMonth() + 1;
        const anoInicial = dataInicial.getFullYear();

        const formattedDate = `${diaInicial < 10 ? "0" + diaInicial : diaInicial}/${mesInicial1 < 10 ? "0" + mesInicial1 : mesInicial1}/${anoInicial}`;

        let dataVencimento = new Date(anoInicial, mesInicial, diaInicial);
        let dataVencimento2 = new Date(anoInicial, mesInicial, diaInicial);

        let dias;
        let totalDias = 16;
        function diasEntreDatas(dataInicio, dataFim) {
            const umDia = 24 * 60 * 60 * 1000; // Um dia em milissegundos
            const diff = Math.abs(dataFim - dataInicio);
            return Math.round(diff / umDia);
        }

        for (let mes = 1; mes <= parseInt(parcelas); mes++) {
            const vencimentoMes = dataVencimento2.getMonth() + 1;
            const vencimentoAno = dataVencimento2.getFullYear();

            if (vencimentoMes === 3) {
                if ((vencimentoAno % 4 === 0 && vencimentoAno % 100 !== 0) || vencimentoAno % 400 === 0) {
                    dias = 15; // Ano bissexto
                } else {
                    dias = 14;
                }
            }
            else if ([1, 2, 4, 6, 8, 9, 11].includes(vencimentoMes)) {
                dias = 16;
            }
            else {
                dias = 15;
            }

            totalDias += dias;
            dataVencimento2.setMonth(dataVencimento2.getMonth() + 1);
        }
        let carenciaIteracao1 = parseInt(carencia);
        let saldoDevedorOriginal = saldoDevedor;
        while (carenciaIteracao1 > 0) {
            const jurosCarencia = saldoDevedorOriginal * jurosMensais;
            if( tipocarencia2 == 2 && carenciaDefault==0){
                saldoDevedorOriginal += jurosCarencia;
            }
            quantidadeParcelas -= 1;
            carenciaIteracao1 -= 1;
        }
        let amortizacao = saldoDevedorOriginal / (quantidadeParcelas + parseInt(carencia));
        let parcelasSemCarencia = (parcelas - parseInt(carencia));
        let totalJuros = 0;
        let custoFinanceiro = 0;
        let html = [];
        for (let mes = 1; mes <= parseInt(parcelas); mes++) {
            const vencimentoDiaAtual = dataVencimento.getDate();
            const vencimentoMesAtual = (dataVencimento.getMonth() + 1).toString().padStart(2, '0');
            const vencimentoAnoAtual = dataVencimento.getFullYear();

            let juros, amortizacaoFinal, parcelaAtual1;
            let financeiro = saldoDevedor * capitalTaxa;
            if (carenciaIteracao > 0) {
                if(tipocarencia2 == 1){
                    juros = inicial * jurosMensais;
                    parcelaAtual1 = juros
                    amortizacaoFinal = 0;
                    inicial = saldoDevedor;
                    carenciaIteracao -= 1;
                }if(tipocarencia2 == 2){
                    parcelaAtual1 = 0;
                    juros = inicial * jurosMensais;
                    amortizacaoFinal = 0;
                    inicial += juros;
                    saldoDevedor += juros;
                    carenciaIteracao -= 1;
                }
                if( tipocarencia2 == 2 && carenciaDefault==0){
                    if (mes === 1) {
                        custoFinanceiro -= financeiro;
                        const dataAtual = new Date();
                        const diasPrimeiraParcela = diasEntreDatas(dataAtual, dataVencimento);
                        const some = (saldoDevedor * jurosMensais / 30);
                        if (diasPrimeiraParcela < 30) { 
                            juros = some * diasPrimeiraParcela;
                        }
                        else{
                            juros = some * 30;
                        };
                    }
                }
            } else {
                if( tipocarencia2 == 2 && carenciaDefault==0){
                    juros = inicial * jurosMensais;
                    parcelaAtual1 = amortizacao + juros;
                    amortizacaoFinal = amortizacao;
                    inicial -= amortizacaoFinal;
                    saldoDevedor -= amortizacaoFinal;
                }else{
                    amortizacao = saldoDevedor / parcelasSemCarencia;
                    parcelasSemCarencia--;
                    juros = saldoDevedor * jurosMensais;
                    parcelaAtual1 = amortizacao + juros;
                    amortizacaoFinal = amortizacao;
                    inicial -= amortizacao;
                    saldoDevedor -= amortizacao;
                }
                custoFinanceiro += financeiro;
            }   
            totalJuros += juros;
            html.push([
                mes, //0
                vencimentoDiaAtual,//1
                vencimentoMesAtual,
                vencimentoAnoAtual,
                parcelaAtual1,
                amortizacaoFinal,
                juros,
                saldoDevedor
            ]);
            dataVencimento.setMonth(dataVencimento.getMonth() + 1);
        }
        getInfos(valorI, amortizacao, custoFinanceiro, valorInicial, emissao, quantidadeParcelasInfo, formattedDate, ccb, taxaFloat, totalDias, totalJuros, valorI, valorI, carencia);
        dispatch(setSacData(html));

        localStorage.setItem('sacData', JSON.stringify(html));
    }

    function getInfos(calculo, valorParcela, jurosCapital, valorInicial, taxaEmissao, parcelas, formattedDate, ccb, taxaFloat, totalDias, totalJuros, valorMenos, valorNovo, carencia) {

        const valor1 = calculo;
        const iof1 = valor1 * 0.0038;
        let iof2 = valor1 * 0.000041 * (parcelas * 30);
        const iofMax = valor1 * 0.015;
        if (iof2 >= iofMax) {
            iof2 = iofMax
        }
        const economiaIOF = iof1 + iof2;
        let ccbFinal = ccb + economiaIOF;

        const majorado = economiaIOF * (1 + (taxaFloat / 100)) ** parcelas;

        const ganhoReal = totalJuros - jurosCapital;
        const spread = totalJuros;
        let desagio = calculo - valorInicial;
        const margemBruta1 = ganhoReal / spread * 100;
        const vencimento = formattedDate
        const saldoDevedor = valor1;
        const saldoDevedor1 = valor1 + spread
        const taxaAoAno = ((1 + (taxaFloat / 100)) ** 12 - 1) * 100

        const allInfoArray = [
            desagio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //0
            margemBruta1.toFixed(2), // 1
            taxaEmissao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 2
            valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 3
            parcelas, // 4
            valorInicial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 5
            valorNovo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 6
            vencimento, // 7
            user, // 8
            jurosCapital.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //9
            taxaFloat, // 10
            jurosCapital.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //11
            saldoDevedor1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //12
            saldoDevedor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 13
            economiaIOF.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //14
            ccbFinal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 15
            totalJuros.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //16
            majorado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //17
            ganhoReal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //18
            carencia, //19
            taxaAoAno.toFixed(2)
        ]

        dispatch(setFinalTotal(allInfoArray));
        localStorage.setItem('allInfoArray', JSON.stringify(allInfoArray));
    }


    return {
        calcularSac
    }
}