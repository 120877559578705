import React, { useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import '../styles/AddClient.css'
import { formatCnpj, isValidCNPJ, isValidEmail } from "../utils/Tools";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";

const AddClient = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [razaoSocial, setRazaoSocial] = useState('');
    const [email, setEmail] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const showError = (message) => {
        setErrorMessage(message);
    };
    const showSuccess = (message) => {
        setSuccessMessage(message);
    };
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleClient = async () => {
        if (razaoSocial.trim().length < 2) {
            showError('Razão Social deve ter pelo menos 2 caracteres.');
            return;
        }

        // Validar o campo Email
        if (!isValidEmail(email)) {
            showError('Email inválido. Por favor, insira um email válido.');
            return;
        }

        // Validar o campo CPF
        if (!isValidCNPJ(cnpj)) {
            showError('CNPJ inválido. Por favor, insira um CNPJ válido.');
            return;
        }

        // Validar o campo Senha
        if (password.length < 6) {
            showError('A senha deve ter pelo menos 6 caracteres.');
            return;
        }
        const role = 'Master'
        try {
            const userRef = user.data.email;
            let response = await fetch(process.env.REACT_APP_BASE_URL + `/master/?userRef=${userRef}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userRef: user.data.email,
                    razaoSocial: razaoSocial,
                    email: email,
                    cnpj: cnpj,
                    role: role,
                    password: password,
                })
            });

            if (response.ok) {
                showError(false)
                showSuccess('Cadastro Realizado Com Sucesso!');
                setTimeout(() => {
                    navigate('/client'); // Redirecionar após um pequeno atraso
                }, 3000);
            } else {
                console.log('Error:', response.status)
                if (response.status === 408) {
                    showError('Email já cadastrado!');
                } else if (response.status === 409) {
                    showError('CPF já cadastrado!');
                } else if (response.status === 411) {
                    showError('Email Cadastrado como Empresa!');
                }
                else {
                    showError('Erro interno do servidor.');
                }
            }
        } catch (error) {
            console.error(error);
            showError(`Erro ao processar a solicitação. ${error}`);
        }
    }
    return (
        <body className="affiliateBody">
            <div className="fixed">
                <Header selected={'affiliate'} />
            </div>
            <main className="affiliateMain">
                <div className="ahead">
                    <h2 className="formH21" style={{ textAlign: "center", marginLeft: '10rem', paddingLeft: 0 }}>
                        Gerenciar Clientes
                    </h2>
                    <div className="div2">
                        <h3 className="formH22">Bem Vindo, {user.data.razaoSocial}</h3>
                    </div>
                </div>
                <form className="affiliateForm">
                    <div className="formTop" onClick={() => navigate('/client')}>
                        <BiArrowBack /> <h4 className="formH1">Adicionar Cliente</h4></div>

                    <div className="affiliateRow">
                        <div className="affiliateColumn">
                            <label className="affiliateLabel">
                            Razão Social
                            </label>
                            <input
                                placeholder="Insira a razão social do afliado"
                                type="text"
                                className="affiliateInput"
                                onChange={(e) => setRazaoSocial(e.target.value)} />
                        </div>
                        <div className="affiliateColumn">
                            <label className="affiliateLabel">
                                CNPJ
                            </label>
                            <input
                                placeholder="00.000.000/000-00"
                                className="affiliateInput"
                                value={formatCnpj(cnpj)}
                                onChange={(e) => setCnpj(e.target.value)}
                                maxLength={18} />
                        </div>
                        <div className="affiliateColumn">
                            <label className="affiliateLabel">
                                E-mail
                            </label>
                            <input
                                placeholder="Insira o e-mail de acesso do afiliado"
                                className="affiliateInput"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="affiliateColumn">
                            <label className="affiliateLabel">
                                Crie uma senha de acesso
                            </label>
                            <div className='divClientPassword'>

                                <input
                                    placeholder="A senha deve ter pelo menos 6 caracteres"
                                    className="affiliateInputPassword"
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                />
                                <div onClick={handleShowPassword} className='iconClient'>
                                    {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="affiliateButtons">
                        <button onClick={() => { navigate('/home') }} type="button" className="affiliateButton2">Cancelar</button>
                        <button onClick={handleClient} type="button" className="affiliateButton1">Adicionar Cliente</button>
                    </div>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                </form >
            </main>
        </body>
    )
}

export default AddClient;