import React, { useState } from "react";
import '../styles/Register.css';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { formatCnpj } from "../utils/Tools";


const Register = () => {

    const [email, setEmail] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [def, setDef] = useState('');
    const [cnpj, setCnpj] = useState('');

    const role = 'Master';
    const isValidEmail = (email) => {
        // Expressão regular para validar o formato do email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const navigate = useNavigate();
    const handleRegister = async () => {
        if (!validateForm()) {
            return;
        }
        const cleanCnpj = cnpj.replace(/[^\d]/g, '');
        if (password === password1) {
            setDef(password)
        }
        try {
            let response = await fetch(process.env.REACT_APP_BASE_URL + '/master', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    razaoSocial: razaoSocial,
                    email: email,
                    cnpj: cleanCnpj,
                    role: role,
                    password: password,
                })
            });

            if (response.ok) {
                window.alert('Cadastro Realizado Com Sucesso!')
                navigate('/')
            } else {
                console.log('Error:', response.status)
                if (response.status === 408) { window.alert('Email já cadastrado! ') };
                if (response.status === 500) window.alert("Erro!",
                    'Erro interno do servidor.');
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Função para validar CNPJ
    const isValidCNPJ = (cnpj) => {
        if (!cnpj) {
            return false;
        }

        // Remove characters that are not digits
        cnpj = cnpj.replace(/[^\d]/g, '');

        // Check if CNPJ has 14 digits
        if (cnpj.length !== 14) {
            return false;
        }

        // Validate CNPJ using the algorithm
        const weights1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        const weights2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

        const slice1 = cnpj.slice(0, 12);
        const slice2 = cnpj.slice(0, 13);

        const digit1 = slice1
            .split('')
            .map((digit, index) => parseInt(digit) * weights1[index])
            .reduce((acc, value) => acc + value) % 11;

        const digit2 = slice2
            .split('')
            .map((digit, index) => parseInt(digit) * weights2[index])
            .reduce((acc, value) => acc + value) % 11;

        const calculatedDigit1 = digit1 < 2 ? 0 : 11 - digit1;
        const calculatedDigit2 = digit2 < 2 ? 0 : 11 - digit2;

        return (
            parseInt(cnpj.charAt(12)) === calculatedDigit1 &&
            parseInt(cnpj.charAt(13)) === calculatedDigit2
        );
    };


    const validateForm = () => {
        if (!email || !razaoSocial || !password || !password1) {
            window.alert("Todos os campos são obrigatórios.");
            return false;
        }

        if (!isValidCNPJ(cnpj)) {
            window.alert("CNPJ inválido. Por favor, insira um CNPJ válido.");
            return false;
        }

        if (!isValidEmail(email)) {
            window.alert("Email inválido. Por favor, insira um email válido.");
            return false;
        }
        if (razaoSocial.length < 2) {
            window.alert("Razão Social é um campo obrigatório.");
            return false;
        }

        if (password.length < 6) {
            window.alert("A senha deve ter no mínimo 6 caracteres.");
            return false;
        }

        if (password !== password1) {
            window.alert("As senhas não coincidem. Por favor, digite novamente.");
            return false;
        }



        return true;
    };

    return (
        <body className="backColor">
            <header className="header">
                <img src="https://i.ibb.co/yYV6fss/verde-branco.png" alt="logo" className="img"></img>
            </header>
            <main className="backColor">
                <div className='box'>
                    <h1>Cadastre-se</h1>
                    <h3>Dados Pessoais</h3>
                    <div className="divCadastro">
                        <div className="divInputs">
                            <label className='label'>
                                Email</label>
                            <input
                                type="email"
                                className="inputR"
                                placeholder="Email"
                                onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                        <div className="divInputs">
                            <label className='label'>
                                Razão Social</label>
                            <input
                                type="text"
                                className="inputR"
                                placeholder="Insira a Razão Social da empresa"
                                onChange={(e) => { setRazaoSocial(e.target.value) }} />
                        </div>
                        <div className="divInputs">
                            <label className='label'>
                                CNPJ</label>
                            <input
                                type="text"
                                value={formatCnpj(cnpj)}
                                maxLength={18}
                                className="inputR"
                                placeholder="Insira o CNPJ da empresa"
                                onChange={(e) => { setCnpj(e.target.value) }} />
                        </div>

                    </div>
                    <h2>Senha</h2>
                    <div className="divCadastro">
                        <div className="divInputs">
                            <label className='label'>
                                Crie sua senha</label>
                            <input
                                type="text"
                                className="inputR"
                                placeholder="*/`\||{]$%@1"
                                onChange={(e) => { setPassword1(e.target.value) }} />
                        </div>
                        <div className="divInputs">
                            <label className='label'>
                                Confirme sua senha</label>
                            <input
                                type="text"
                                className="inputR"
                                placeholder="Deve ser igual a primeira"
                                onChange={(e) => { setPassword(e.target.value) }} />
                        </div>
                    </div>
                    <div>
                        <h4>Já possui uma conta? <button onClick={() => { navigate('/') }} className="botao">Entre aqui.</button></h4>
                        <button onClick={handleRegister} className="button">Cadastrar</button>
                    </div>
                </div>
            </main >

        </body >
    );
};

export default Register;
