// App.js
//import React from 'react';
//import {useEffect } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/rootReducer';
import Login from './pages/Login';
import Register from './pages/Register';
import Result from './pages/Result';
import Home from './pages/Home';
import Account from './pages/Account';
import Update from './pages/Update';
import EnviarArquivos from './components/EnviarArquivos';
import DocGen from './pages/DocGen';
import Investidor from './pages/Investidor';
import Emissor from './pages/Emissor';
import Inicio from './pages/Inicio';
import AddEmployee from './pages/AddEmployee';
import EmittedNotes from './pages/EmittedNotes';
import Client from './pages/Client';
import AddClient from './pages/AddClient';
import ErrorBoundary from './components/ErrorBoundary'; // Importe o ErrorBoundary
import ErrorPage from './pages/ErrorPage'; // Importe a página de erro
import Irregular from './components/Irregular';
import Billets from './pages/Billets';
import Garantias from './pages/Garantias';
import ForgetPassword from './pages/ForgetPassword';
import React, { useEffect } from 'react';

function App() {
  document.title = 'Nota Comercial';
  const store = configureStore({
    reducer: rootReducer,
  });

  useEffect(() => {
    // Função para injetar o script de proteção contra clickjacking
    const injectClickjackingProtectionScript = () => {
      const script = document.createElement('script');
      script.text = `
      if (top !== self) {
        top.location = self.location;
      }
    `;
      script.async = true;
      document.body.appendChild(script);
      // Injeta o script quando o componente é montado
    };
    injectClickjackingProtectionScript();
     // Limpa o script quando o componente é desmontado
     return () => {
      const script = document.querySelector('script[text="if (top !== self) { top.location = self.location; }"]');
      if (script) {
        document.body.removeChild(script);
      }
    };

   
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path='/result' element={<Result />} />
            <Route path='/account' element={<Account />} />
            <Route path='/profile' element={<Account />} />
            <Route path='/update' element={<Update />} />
            <Route path='/upload' element={<EnviarArquivos />} />
            <Route path='/docgen' element={<DocGen />} />
            <Route path='/Investidor' element={<Investidor />} />
            <Route path='/Emissor' element={<Emissor />} />
            <Route path='/Inicio' element={<Inicio />} />
            <Route path='/client' element={<Client />} />  
            <Route path='/addEmployee' element={<AddEmployee />} />
            <Route path='/addClient' element={<AddClient />} />
            <Route path='/emitted' element={<EmittedNotes />} />
            <Route path='/error' element={<ErrorPage />} />
            <Route path='/irregular' element={<Irregular />} />
            <Route path='/billets' element={<Billets />} />
            <Route path='garantias' element={<Garantias />} />
            <Route path='/reset-password' element={<ForgetPassword />} />
          </Routes>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
}

export default App;
