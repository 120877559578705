import React from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import '../styles/Investidors.css';
import { FaFolderClosed } from "react-icons/fa6";
import {  IoIosDownload } from 'react-icons/io';
import { formatCnpj, formatCpf, formatDocument, formatRg } from "../utils/Tools";

const DetailsEmissor = ({ selectedEmissor, handleSave, onClose }) => {
    return (
        <div>
            <div className="modal">
                <div className="modal-content" style={{ overflowY: 'auto' /* Adiciona a barra de rolagem vertical se necessário */}}>
                    <button className="modalClose" onClick={onClose}>
                        X
                    </button>
                    <h3 className="per1">Detalhes do Emissor Selecionado:</h3>
                    <div className="investidorContainer">
                        <div className="investidorField" style={{width:"90%"}}>
                            <div className="invesP">Origem:</div>
                            <div className="invesP3">{selectedEmissor?.origemRazaoSocial}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Razão Social:</div>
                            <div className="invesP3">{selectedEmissor?.razaoSocial}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">CNPJ:</div>
                            <div className="invesP3">{selectedEmissor?.cnpj}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Representante:</div>
                            <div className="invesP3">{selectedEmissor?.representante}</div>
                            <div className="invesP">Email:</div>
                            <div className="invesP3">{selectedEmissor?.email}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Endereço:</div>
                            <div className="invesP3">
                                CEP: {selectedEmissor?.cep},<br />
                                Rua: {selectedEmissor?.rua},<br />
                                Bairro: {selectedEmissor?.bairro},<br />
                                Cidade: {selectedEmissor?.cidade},<br />
                                UF: {selectedEmissor?.estado}
                            </div>
                        </div>
                        <div className="investidorField">
                            <a href={selectedEmissor.faturamento ? selectedEmissor.faturamento : '#'} className={`customFileInput2 ${selectedEmissor.faturamento ? '' : 'opacoButton'}`} download={selectedEmissor.faturamento ? 'CNH' : ''} >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Relatório de Faturamento`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={selectedEmissor.social ? selectedEmissor.social : '#'} className={`customFileInput2 ${selectedEmissor.social ? '' : 'opacoButton'}`} download={selectedEmissor.social ? 'Social' : ''} >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadTeYxt">{`Baixar Contrato Social`} </span>
                            </a>
                        </div>
                    </div>
                    {selectedEmissor?.avalistas.map((avalista, index) => 
                    <div className="investidorContainer">
                        <h3 className="per1">Avalista  {index+1}</h3>
                        <div className="investidorField">
                            <div className="invesP">Nome:</div>
                            <div className="invesP3">{avalista.aNome}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">CPF</div>
                            <div className="invesP3">{avalista.aCpf}</div>
                        </div>
                        <div className="investidorField">
                            <a href={avalista.aCNH ? avalista.aCNH : '#'} className={`customFileInput2 ${avalista.aCNH ? '' : 'opacoButton'}`} download={avalista.aCNH ? 'CNH' : ''} >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Carteira Nacional de Habilitação`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={avalista.aCasamento ? avalista.aResidencia : '#'} className={`customFileInput2 ${avalista.aResidencia ? '' : 'opacoButton'}`} download={avalista.aResidencia ? 'Residencia' : ''}  >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Comprovante de Residência`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={avalista.aCasamento ? avalista.aCasamento : '#'} className={`customFileInput2 ${avalista.aCasamento ? '' : 'opacoButton'}`} download={avalista.aCasamento ? 'Casamento' : ''}  >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Certidão de Casamento`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                           
                        </div>
                    </div>
                    )}
                    <h3 className="per1">Fiel Depositário</h3>
                    <div className="investidorContainer">
                    <div className="investidorField">
                            <div className="invesP">Nome:</div>
                            <div className="invesP3">{selectedEmissor?.fielDepositario.nome}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">CPF</div>
                            <div className="invesP3">{selectedEmissor?.fielDepositario.fCpf}</div>
                        </div>
                        <div className="investidorField">
                            <a href={selectedEmissor.fielDepositario.fCNH ? selectedEmissor.fielDepositario.fCNH : '#'} className={`customFileInput2 ${selectedEmissor.fielDepositario.fCNH ? '' : 'opacoButton'}`} download={selectedEmissor.fielDepositario.fCNH ? 'CNH' : ''} >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Carteira Nacional de Habilitação`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={selectedEmissor.fielDepositario.fResidencia ? selectedEmissor.fielDepositario.fResidencia : '#'} className={`customFileInput2 ${selectedEmissor.fielDepositario.fResidencia ? '' : 'opacoButton'}`} download={selectedEmissor.fielDepositario.fResidencia ? 'Residencica' : ''} >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Comprovante de Residência`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={selectedEmissor.fielDepositario.fCasamento ? selectedEmissor.fielDepositario.fCasamento : '#'} className={`customFileInput2 ${selectedEmissor.fielDepositario.fCasamento ? '' : 'opacoButton'}`} download={selectedEmissor.fielDepositario.fCasamento ? 'Certidao' : ''} >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Certidão de Casamento`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                           
                        </div>
                    </div>
                      {/*  <div className="investidorField2">
                            <div className="invesP">Avalistas:</div>
                            <div className="invesP3">{selectedEmissor?.avalistas.map((avalista, index) => (<div>
                                Avalista {index + 1}
                                . {<div className="divInputsDocAva">
                                <label htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                    CPF/CNPJ:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDoc`}
                                    value={formatDocument(avalista.aCpf)}
                                    maxLength={19}
                                />
                            </div>},{<a className='customFileInput2' >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Contrato Social`} </span>
                            </a>}
                            </div>))}</div>
                            </div>*/}
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                        <button onClick={handleSave} className="newButtonInves">Selecionar Emissor</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailsEmissor;
