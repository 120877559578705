import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import '../styles/DocGen.css';
import SingularePdf1 from "../utils/pdfs/singularePdf1";
import SingulareSemGarantia from "../utils/pdfs/SingulareSemGarantia";
import SingulareComGarantia from "../utils/pdfs/SingulareComGarantia";
import VortxPdf from "../utils/pdfs/VortxPdf";
import VortxSemGarantia from "../utils/pdfs/VortxSemGarantia";
import { useSelector } from "react-redux";
import { generateCNABFile } from '../utils/CNABGenerator';
import { FaRegFileLines, FaRegFilePdf } from "react-icons/fa6";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import TrusteeSemGarantia from "../utils/pdfs/TrusteeSemGarantia";
import TrusteeComGarantia from "../utils/pdfs/TrusteeComGarantia";
import TrusteeCondicoes from "../utils/pdfs/trusteeCondicoes";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PDFDownloadLink, PDFViewer, pdf, renderToBuffer, renderToFile } from "@react-pdf/renderer";
import PostNote from "../components/PostNotes"; // Importe o componente PostNote aqui
import FileSaver from 'file-saver';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import authenticate from "../utils/it4";
import { formatDate, parseDate, parseDateString } from "../utils/Tools";
import notaIt4 from "../utils/it4";
import { NumericFormat } from "react-number-format";


const DocGen = () => {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
    const [available, setAvailable] = useState(false);
    const [semGarantia, setSemGarantia] = useState(false);
    const [comGarantia, setComGarantia] = useState(false);
    const [vortxAvailable, setVortxAvailable] = useState(false);
    const [modoSingulareExpandido, setModoSingulareExpandido] = useState(false);
    const [modoVortxExpandido, setModoVortxExpandido] = useState(false);
    const [vortxSemGarantia, setVortxSemGarantia] = useState(false);
    const [trusteeSemGarantia, setTrusteeSemGarantia] = useState(false);
    const [trusteeComGarantia, setTrusteeComGarantia] = useState(false);
    const [modoCnabExpandido, setModoCnabExpandido] = useState(false);
    const [trusteeAvailable, setTrusteeAvailable] = useState(false);
    const [emissao, setEmissao] = useState('1ª (PRIMEIRA)');
    const [indexador, setIndexador] = useState('Pré-Fixado');
    const [sendingNote, setSendingNote] = useState(true); // Estado para controlar o envio da nota
    const [prcntgm, setPrcntgm] = useState('');
    const [generatedPDF, setGeneratedPDF] = useState(null);
    const [fourDigit, setFourDigit] = useState(['', '', '', '']);
    const [controleParticipante, setControleParticipante] = useState('');
    const [enviandoEscri, setEnviandoEscri] = useState(false);
    const inputRefs = useRef([null, null, null, null]);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [qualFoi, setQualFoi] = useState('');
    const navigate = useNavigate()
   
    const generateBlobFromComponent = async (component) => {
        const pdfString = await TrusteeSemGarantia.toPdfString(component);
        return new Blob([pdfString], { type: 'application/pdf' });
    };

    const emissorPreenchido = JSON.parse(localStorage.getItem('emissorPreenchido'));
    const investidorPreenchido = JSON.parse(localStorage.getItem('investidorPreenchido'));
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    const garantiaDataItem = localStorage.getItem('garantiaData');
    let garantiaData;

    if (garantiaDataItem) {
        try {
            garantiaData = JSON.parse(garantiaDataItem);
        } catch (e) {
            console.error('Erro ao analisar os dados da garantia:', e);
            // Trate o erro conforme necessário, talvez definindo garantiaData como null ou um objeto vazio
        }
    } else {
        console.warn('Nenhum dado de garantia encontrado no localStorage.');
        // Trate a ausência de dados conforme necessário
    }

    const user = JSON.parse(localStorage.getItem('user'));
    const calcData = JSON.parse(localStorage.getItem('calcData'));
    const sacData = useSelector((state) => state.sacData.sacData);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const toggleModoSingulare = () => {
        setModoSingulareExpandido(!modoSingulareExpandido);
    };

    const toggleModoCnab = () => {
        setModoCnabExpandido(!modoCnabExpandido);
    };

    const toggleModoVortx = () => {
        setModoVortxExpandido(!modoVortxExpandido);
    };
    const validateFields = (data, objName) => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                // Verifique se o valor é nulo, uma string vazia ou contém apenas espaços
                if (value === null || (typeof value === 'string' && value.trim() === '')) {
                    // Verifique se a chave é uma das exceções
                    if (
                        key !== 'complemento' &&
                        key !== 'garantia' &&
                        key !== 'gTipo' &&
                        key !== 'gNatureza' &&
                        key !== 'gDescricao' &&
                        key !== 'gDocumento' &&
                        key !== 'gProprietario' &&
                        key !== 'gQuantidade' &&
                        key !== 'nome2'
                    ) {
                        window.alert(`O campo "${key}" em ${objName} não pode estar vazio.`);
                        return false;
                    }
                }
            }
        }
        return true;
    };


    useEffect(() => {
        if (!emissor || !investidor || !user || !calcData || !sacData) {
            console.error("Alguma variável essencial é undefined ou nula");
            setTimeout(() => {
                window.alert('Cálculo não realizado!');
                navigate('/home');
            }, 1)
        } else if (!emissor?.razaoSocial) {
            window.alert('Emissor deve ser preenchido');
            navigate('/emissor');
        }
        window.scrollTo(0, 0);
    }, [emissor, investidor, user, calcData, sacData, navigate]);

    useEffect(() => {
        if (emissor?.razaoSocial === null) {
            window.alert('Emissor deve ser preenchido');
            navigate('/emissor');
        }
        window.scrollTo(0, 0);
    }, []);

    const dados = {
        emissao,
        indexador,
        prcntgm
    }
    const garantia = JSON.parse(localStorage.getItem('garantia'));


    const handleCnab = () => {
        console.log('ta chamando');
        let newDigits = fourDigit.join('')
        console.log(newDigits)
        if (allInfoArray[4] > 0) {
            const infoArray = {
                nomeOriginador: emissor?.razaoSocial, //0
                bancoOrigNumber: emissor?.bankNumber, //1
                bancoOrig: emissor?.banco, //2
                conta: emissor?.conta, //3
                agency: emissor?.agency, //4
                idTitulo: controleParticipante, //5
                idDocumento: newDigits, //6
                pessoa: 'Juridica', //7
                nTC: '', //8
                cnpjSacado: emissor?.cnpj, //9
                endereco: emissor?.rua, //10
                numeroNotaFiscal: '',//11
                formattedCep: emissor?.cep, //12
                formattedCnpj: emissor?.cnpj, //13
                bankNumber: emissor?.bankNumber,//14
                selectedBank: emissor?.banco, //15
                //idTitulo, 16
                razaoSacado: emissor?.razao, //17
                enderecoSacado: emissor?.rua, //18
                formattedCepSacado: emissor?.cep, //19
                razaoCedente: emissor?.razao, //20
                newDigits: newDigits, //21
            };
            console.log(infoArray);
            generateCNABFile(infoArray, allInfoArray);
        }
    };
    const handleAvailable = (type) => {

        if (!emissor) {
            window.alert('O formulário do Emissor não foi preenchido!');
            return;
        }

        if (!investidor) {
            window.alert('O formulário do Investidor não foi preenchido!');
            return;
        }

        if (!validateFields(emissor, "emissor") || !validateFields(investidor, "investidor")) {
            return;
        }
        if (allInfoArray.length < 1) {
            window.alert('Cálculo não realizado!');
            return;
        }

        if (type === 'semGarantia') {
            setSemGarantia(!semGarantia); // Alterna entre verdadeiro e falso
            setAvailable(false); // Certifica-se de fechar a outra seção
            setComGarantia(false);
            setTrusteeSemGarantia(false)
            setVortxAvailable(false);
            setTrusteeComGarantia(false)
            setVortxSemGarantia(false);

        } else if (type === 'available') {
            setComGarantia(false)
            setSemGarantia(false); // Certifica-se de fechar a outra seção
            setAvailable(!available); // Alterna entre verdadeiro e falso
            setTrusteeAvailable(false)
            setVortxAvailable(false);
            setTrusteeSemGarantia(false)
            setVortxSemGarantia(false);
            setTrusteeComGarantia(false)

        } else if (type === 'comGarantia') {
            setComGarantia(!comGarantia); // Alterna entre verdadeiro e falso
            setSemGarantia(false); // Certifica-se de fechar a outra seção
            setAvailable(false); // Alterna entre verdadeiro e falso
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeComGarantia(false)
            setTrusteeAvailable(false)
            setTrusteeSemGarantia(false)

        } else if (type === 'vortxAvailable') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(!vortxAvailable);
            setVortxSemGarantia(false);
            setTrusteeSemGarantia(false)
            setTrusteeAvailable(false)
            setTrusteeComGarantia(false)

        } else if (type === 'vortxSemGarantia') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setTrusteeAvailable(false)
            setVortxSemGarantia(!vortxSemGarantia);
            setTrusteeSemGarantia(false)
            setTrusteeComGarantia(false)

        } else if (type === 'trusteeSemGarantia') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeAvailable(false)
            if (garantia === false) {
                setTrusteeComGarantia(false);
                setTrusteeSemGarantia(true);
                setQualFoi('trusteeSemGarantia')
                handleAnexo()
            } else {
                setTrusteeComGarantia(true);
                setTrusteeSemGarantia(false);
                setQualFoi('trusteeComGarantia')
            }
        } else if (type === 'trusteeComGarantia') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeSemGarantia(false);
            setTrusteeComGarantia(!trusteeComGarantia);
            setTrusteeAvailable(false)
        } else if (type === 'trusteeAvailable') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeSemGarantia(false);
            setTrusteeComGarantia(false);
            setTrusteeAvailable(!trusteeAvailable);
        }
    };
    const now = new Date();
    let dataArray = [];
    if (allInfoArray && allInfoArray[7]) {
        dataArray = allInfoArray[7].split('/');
    } else {
        console.error("allInfoArray[7] is undefined");
        return null;
    }

    let vencimentoArray = [];
    if (formValues && formValues.vencimento) {
        vencimentoArray = formValues.vencimento.split('/');
    } else {
        console.error("formValues.vencimento is undefined");
    }


    const linhaDoVencimento = calcData && calcData.length > 0
        ? calcData[calcData.length - 1]
        : sacData && sacData.length > 0
            ? sacData[sacData.length - 1]
            : null;

    if (!linhaDoVencimento) {
        console.error("linhaDoVencimento is undefined");
        return null;
    }


    let dataDoVencimento;
    if (linhaDoVencimento[1] && linhaDoVencimento[2] && linhaDoVencimento[3]) {
        dataDoVencimento = `${linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}` : linhaDoVencimento[1]
            }/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    } else {
        console.error("Data do vencimento está incompleta");
        return null;
    }
    const dataEmissao = formatDate(now);
    const data1 = parseDateString(dataEmissao);
    const data2 = parseDate(dataDoVencimento);
    const diffEmMilissegundos = data2 - data1;
    const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
    const totalDias = Math.floor(diffEmMilissegundos / umDiaEmMilissegundos);

    let primeiroNomeAbreviado;
    if (emissor?.razaoSocial) {
        primeiroNomeAbreviado = emissor?.razaoSocial.split(' ')[0];
    } else {
        console.error("emissor.razaoSocial is undefined");
        return null;
    }

    /*     const dataIt4 = {
            Cnpj: dados.cnpj,
            Nome: dados.Nome,
            NomeAbreviado: dados.NomeAbreviado,
            Isin: dados.Isin || "BRBR6Q769LYK18",
            DataInicialCustodia: dados.DataInicialCustodia || "2023-06-05",
            DataAtualCustodia: dados.DataAtualCustodia || "2023-11-16",
            DataPrevistaVencimento: dados.DataPrevistaVencimento || "2025-12-14",
            SubTipo: dados.SubTipo || 192,
            Modalidade: dados.Modalidade || null,
            Contrato: dados.Contrato || "85601",
            DescricaoGarantia: dados.DescricaoGarantia || "Itaque et nobis molestiae exercitationem quidem alias. At laudantium aut tempore commodi. Ut sit veritatis ut sunt aut. Sit nihil nobis incidunt facere omnis et.",
            Dados: {
                QuantidadeEmitida: dados.Dados.QuantidadeEmitida || 310.0,
                PUEmissao: dados.Dados.puEmissao || 7.72,
                ValorEmissao: dados.Dados.ValorEmissao || 2393.20,
                DataVencimento: dados.Dados.DataVencimento || "2025-12-14",
                DataInicioRentabilidade: dados.Dados.DataInicioRentabilidade || "2023-11-10",
                TipoPagJuros: dados.Dados.TipoPagJuros || "bullet",
                TipoPagAmortizacao: dados.Dados.TipoPagAmortizacao || "anual",
                CodSerieIndexador: dados.Dados.CodSerieIndexador || 188,
                PercRemuneracao: dados.Dados.PercRemuneracao || 39.4523386561556000,
                TaxaPreMes: dados.Dados.TaxaPreMes || 0.593475,
                NumeroDiasTaxa: dados.Dados.NumeroDiasTaxa || 252,
                IndicesInflacao: dados.Dados.IndicesInflacao || null
            },
            Emissor: {
                Cnpj: dados.Emissor.Cnpj || 51977990000153,
                Dados: {
                    Nome: dados.Emissor.Dados.Nome || "Teste Nome Um",
                    Isin: dados.Emissor.Dados.Isin || "BR0LK7I39050",
                    Email: dados.Emissor.Dados.Email || "testenome@yahoo.com",
                    CVM: dados.Emissor.Dados.CVM || false,
                    Endereco: {
                        Cep: dados.Emissor.Dados.Endereco.Cep || 74777118,
                        Endereco: dados.Emissor.Dados.Endereco.Endereco || "Mariana Rodovia",
                        EnderecoNumero: dados.Emissor.Dados.Endereco.EnderecoNumero || "607",
                        EnderecoComplemento: dados.Emissor.Dados.Endereco.EnderecoComplemento || "29254689",
                        Bairro: dados.Emissor.Dados.Endereco.Bairro || "Gabriel Alameda",
                        Cidade: dados.Emissor.Dados.Endereco.Cidade || "Belo Horizonte",
                        UF: dados.Emissor.Dados.Endereco.UF || "PA"
                    }
                }
            },
            Administrador: {
                Cnpj: dados.Administrador.Cnpj || 51977990000153,
                Dados: {
                    PathLogotipoAdministrador: "/sbin/architect.hh",
                    PathImagemTextoCarta: "/Network/static_online_implement.install",
                    PathAssinaturaCarta: "/private/tmp/kids.psf",
                    RelPdfSenhaMaster: "USL51K4On2",
                    RelPdfPrefixoSenhaCliente: "69clWUDbXM",
                    RespDirfNome: "Caio Braga",
                    RespDirfCpf: "764.768.985-15",
                    RespDirfEmail: "CaioBraga_Carvalho@live.com",
                    RespDirfDDD: "57",
                    RespDirfTelefone: "63155843",
                    RespDirfRamal: null,
                    RespDirfFax: "92298004",
                    DirfNatureza: "5",
                    DirfCpfResponsavelCnpj: "764.768.985-15",
                    DirfRegimeFII: "1",
                    DirfIndicadorSocioOstensivo: true,
                    DirfIndicadorDeclaranteDepositario: true,
                    DirfIndicadorDeclaranteInstituicao: true,
                    DirfIndicadorDeclaranteRendPagExterior: true,
                    DirfIndicadorPlanoPrivado: true,
                    DirfIndicadorPagOlimpiadas: true,
                    DirfIndicadorUniaoDetemMaioria: true,
                    DirfIndicadorSituacaoEspecial: false,
                    Nome: "Silva e Associados",
                    Isin: "BR54R0X5B006",
                    Email: "SilvaeAssociados19@gmail.com",
                    CVM: null,
                    Endereco: {
                        Cep: 22192561,
                        Endereco: "Maria Alice Avenida",
                        EnderecoNumero: "787",
                        EnderecoComplemento: "1482176349",
                        Bairro: "Fabiano Rua",
                        Cidade: "São Bernardo do Campo",
                        UF: "MA"
                    }
                }
            },
            custodiante: {
                Cnpj: dados.custodiante.Cnpj,
                Dados: {
                    Nome: dados.custodiante.Dados.Nome,
                    Isin: "BREIYAM39130",
                    Email: dados.custodiante.Dados.Email,
                    CVM: true,
                    Endereco: {
                        Cep: dados.custodiante.Dados.Endereco.Cep,
                        Endereco: dados.custodiante.Dados.Endereco.Endereco,
                        EnderecoNumero: dados.custodiante.Dados.Endereco.EnderecoNumero,
                        EnderecoComplemento: dados.custodiante.Dados.Endereco.EnderecoComplemento,
                        Bairro: dados.custodiante.Dados.Endereco.Bairro,
                        Cidade: dados.custodiante.Dados.Endereco.Cidade,
                        UF: dados.custodiante.Dados.Endereco.UF
                    }
                }
            },
            liquidante: {
                Cnpj: 51977990000153,
                Dados: {
                    Nome: "Reis EIRELI",
                    Isin: "BR8Z7Y39KF11",
                    Email: "ReisEIRELI82@hotmail.com",
                    CVM: true,
                    Endereco: {
                        Cep: 12742045,
                        Endereco: "Murilo Alameda",
                        EnderecoNumero: "6543",
                        EnderecoComplemento: "1748099278",
                        Bairro: "Margarida Avenida",
                        Cidade: "Limeira",
                        UF: "TO"
                    }
                }
            },
            Investidores: dados.investidores || null,
            AgendaPagamentos: [
                {
                    Data: "2026-08-19",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 486.40
                },
                {
                    Data: "2023-12-10",
                    HasJuros: true,
                    HasAmortizacao: true,
                    ValorPrevisto: 165.73
                },
                {
                    Data: "2026-07-25",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 715.24
                },
                {
                    Data: "2026-05-15",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 750.36
                },
                {
                    Data: "2025-11-15",
                    HasJuros: true,
                    HasAmortizacao: true,
                    ValorPrevisto: 712.28
                },
                {
                    Data: "2026-07-11",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 768.06
                },
                {
                    Data: "2024-10-07",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 335.16
                },
                {
                    Data: "2026-06-25",
                    HasJuros: true,
                    HasAmortizacao: false,
                    ValorPrevisto: 589.96
                }
            ]
    
        } */
    const handleEscri = async () => {
        setEnviandoEscri(true);
        notaIt4();
        setTimeout(() => {
            setEnviandoEscri(false);
            setTimeout(() => {
                alert('Enviado com Sucesso!');
            }, 1000);
        }, 3000);
    };

    const handleFourDigit = (index, newValue) => {
        const newValues = [...fourDigit];
        newValues[index] = newValue;
        setFourDigit(newValues);
        if (newValue && index < 3) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && !fourDigit[index]) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePrcntg = (e) => {
        let inputValue = e.target.value.replace(/[^0-9.%]/g, '');

        // Remove múltiplos % e garante que há no máximo duas casas decimais após o ponto
        inputValue = inputValue
            .replace(/%/g, '')
            .replace(/\.(?=.*\.)/g, '')
            .replace(/(\.\d{2})\d+$/g, '$1');

        setPrcntgm(inputValue);
    };


    const handleBlur = () => {
        const floatValue = parseFloat(prcntgm.replace(/%/g, ''));
        if (!isNaN(floatValue)) {
            const formattedValue = `${floatValue.toFixed(2)}%`;
            setPrcntgm(formattedValue);
        }
    };

    const handleAnexo = async () => {
        console.log('Enviando...')
        const seuComponentePDF = <TrusteeSemGarantia dados={dados} />;

        const blob = await pdf(seuComponentePDF).toBlob();

        const formData = new FormData();
        formData.append('file', blob, 'documento.pdf'); // 'documento.pdf' é o nome do arquivo
        console.log(formData, 'formData');
        const objetoJson = {
            userRef: user.data.email,
            emissor: emissor,
            investidor: investidor,
            valorOperacao: formValues.valorTotal,
            parcelas: formValues.parcelas,
        }

        formData.append('jsonDados', JSON.stringify(objetoJson));

        // Fazendo a requisição para o servidor
        fetch('http://localhost:6024/minuta/', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Resposta do servidor:', data);
            })
            .catch(error => {
                console.error('Erro ao enviar o PDF e os dados:', error);
            });
    };
  
    
    return (
        <body className="homeBody">
            <div className="fixed">
                <Header selected={'docgen'} />
            </div>
            <div className="mainDoc">
                <div className="titlee">
                    <h1>Emitir Nota Comercial</h1>
                </div>

                <div className="mainDoc1" style={{
                    marginLeft: '4%',
                }}>

                    <h2>INDEXADOR / CONTROLE DE EMISSÃO</h2>
                    <div className="rowDivs1">
                        <div className="divInputsDoc5" >
                            <label htmlFor="" className="labelInputsDoc">
                                Controle de Emissão
                            </label>
                            <select
                                className="selectDoc"
                                value={emissao}
                                onChange={(e) => { setEmissao(e.target.value) }}
                            >
        
                                <option value="1ª (PRIMEIRA)">1ª (PRIMEIRA)</option>
                                <option value="2ª (SEGUNDA)">2ª (SEGUNDA)</option>
                                <option value="3ª (TERCEIRA)">3ª (TERCEIRA)</option>
                                <option value="4ª (QUARTA)">4ª (QUARTA)</option>
                                <option value="5ª (QUINTA)">5ª (QUINTA)</option>
                                <option value="6ª (SEXTA)">6ª (SEXTA)</option>
                                <option value="7ª (SÉTIMA)">7ª (SÉTIMA)</option>
                                <option value="8ª (OITAVA)">8ª (OITAVA)</option>
                                <option value="9ª (NONA)">9ª (NONA)</option>
                                <option value="10ª (DÉCIMA)">10ª (DÉCIMA)</option>
                                <option value="11ª (DÉCIMA PRIMEIRA)">11ª (DÉCIMA PRIMEIRA)</option>
                                <option value="12ª (DÉCIMA SEGUNDA)">12ª (DÉCIMA SEGUNDA)</option>
                                <option value="13ª (DÉCIMA TERCEIRA)">13ª (DÉCIMA TERCEIRA)</option>
                                <option value="14ª (DÉCIMA QUARTA)">14ª (DÉCIMA QUARTA)</option>
                                <option value="15ª (DÉCIMA QUINTA)">15ª (DÉCIMA QUINTA)</option>
                                <option value="16ª (DÉCIMA SEXTA)">16ª (DÉCIMA SEXTA)</option>
                                <option value="17ª (DÉCIMA SÉTIMA)">17ª (DÉCIMA SÉTIMA)</option>
                                <option value="18ª (DÉCIMA OITAVA)">18ª (DÉCIMA OITAVA)</option>
                                <option value="19ª (DÉCIMA NONA)">19ª (DÉCIMA NONA)</option>
                                <option value="20ª (VIGÉSIMA)">20ª (VIGÉSIMA)</option>
                            </select>
                        </div>
                        <div className="divInputsDoc5">
                            <label htmlFor="" className="labelInputsDoc">
                                Indexador
                            </label>
                            <select
                                className="selectDoc"
                                value={indexador}
                                onChange={(e) => { setIndexador(e.target.value) }}
                            >
                                <option value="Pré-Fixado">Pré-Fixado</option>
                                <option value="IPCA">IPCA</option>
                                <option value="CDI">CDI</option>
                                <option value="TR">TR</option>
                                <option value="IGPM">IGPM</option>
                            </select>
                        </div>
                        {indexador === 'Pré-Fixado' ? null :
                            <div className="divInputsDoc1">
                                <label htmlFor="" className="labelInputsDoc" style={{ width: '92%' }}>
                                    Defina a porcentagem do indexador
                                </label>
                                <NumericFormat
                                    className='inputsDoc'
                                    value={''}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix={'%'}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder={`${prcntgm}%`}
                                    onValueChange={(values) => {
                                        setPrcntgm(values.value)
                                    }}
                                    required
                                />
                            </div>
                        }
                        {modoCnabExpandido &&
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', margin: '30px', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                                    <label style={{ marginBottom: '10px', width: '100%', textAlign: 'center' }}>
                                        Código da Escrituração (Apenas para CNAB)
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', alignContent: 'flex-start', alignItems: 'flex-start' }}>
                                        {fourDigit.map((digit, index) => (
                                            <input
                                                key={index}
                                                className="inputsQuadradinho"
                                                value={digit}
                                                onChange={(e) => handleFourDigit(index, e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                maxLength="1"
                                                ref={(input) => (inputRefs.current[index] = input)}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'START', width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>
                                        Número do Título
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                                        <input
                                            className="inputsDoc"
                                            value={controleParticipante}
                                            onChange={(e) => setControleParticipante(e.target.value)}
                                            maxLength={24}
                                        />
                                    </div>
                                </div>

                            </div>}
                        {sendingNote ? (
                            <PostNote onNotePosted={sendingNote} dados={dados} />

                        ) : (
                            <div className="rowDivs1">
                                <div className="DocGenHeader">
                                    <div onClick={toggleModoSingulare} className="DocGenButton">
                                        {modoSingulareExpandido ? <div className="divIconAndText">Fechar Minuta <AiOutlineUp /></div> : <div className="divIconAndText">Emitir Minuta <AiOutlineDown /></div>}
                                    </div>
                                                <div onClick={() => handleAvailable('semGarantia')} className="docButton1">
                                                    {semGarantia ? 'Fechar Nota Comercial Singulare' : 'Modelo Singulare'}
                                                </div>
                                    {modoSingulareExpandido && (
                                        <div className="buttonsContainer">
                                            <div onClick={() => handleAvailable('trusteeSemGarantia')} className="docButton1">
                                                {trusteeSemGarantia ? 'Fechar Nota Comercial' : 'Modelo Trustee'}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="DocGenHeader">
                                    <div onClick={toggleModoVortx} className="DocGenButton">
                                        {modoVortxExpandido ? <div className="divIconAndText">Fechar Condições <AiOutlineUp /></div> : <div className="divIconAndText">Emitir Condições <AiOutlineDown /></div>}
                                                <div onClick={() => handleAvailable('available')} className="docButton1">
                                                    {available ? 'Fechar Condições Negociais' : 'Modelo Singulare'}
                                                </div>
                                    </div>
                                    {modoVortxExpandido && (
                                        <div className="buttonsContainer">
                                            <div onClick={() => handleAvailable('trusteeAvailable')} className="docButton1">
                                                {vortxAvailable ? 'Fechar Condições Negociais' : 'Modelo Trustee'}
                                            </div>
                                        </div>
                                    )}
                                    <div className="DocGenDiv">
                                        {available ?
                                            <div className={available ? "pdf-container active" : "pdf-container"}>
                                                <SingularePdf1 dados={dados} />
                                                <div onClick={(e) => { handleAvailable('available') }} className="close-button1">
                                                    X
                                                </div>
                                            </div> : null}
                                        {semGarantia ?
                                            <div className={semGarantia ? "pdf-container active" : "pdf-container"}>
                                                {/*singulare*/}
                                                <SingulareSemGarantia dados={dados}/>
                                                <div onClick={(e) => { handleAvailable('semGarantia') }} className="close-button1">X</div>
                                            </div> : null}

                                        {trusteeSemGarantia ?
                                            <div className={trusteeSemGarantia ? "pdf-container active" : "pdf-container"}>
                                                <PDFViewer style={{ width: '90%', height: '90%' }}>
                                                    <TrusteeSemGarantia dados={dados} />
                                                </PDFViewer>
                                                <div onClick={(e) => { setTrusteeSemGarantia(false) }} className="close-button1">X</div>
                                            </div>
                                            : null}

                                        {trusteeComGarantia ?
                                            <div className={trusteeComGarantia ? "pdf-container active" : "pdf-container"}>
                                                <TrusteeComGarantia dados={dados} />

                                                <div onClick={(e) => { handleAvailable('trusteeComGarantia') }} className="close-button1">X</div>
                                            </div>
                                            : null}
                                        {vortxAvailable ?
                                            <div className={vortxAvailable ? "pdf-container active" : "pdf-container"}>
                                                <div onClick={(e) => { handleAvailable('vortxAvailable') }} className="close-button1">X</div>
                                                <VortxPdf dados={dados} />
                                            </div>
                                            : null}
                                        {vortxSemGarantia ?
                                            <div className={VortxSemGarantia ? "pdf-container active" : "pdf-container"}>
                                                <div onClick={(e) => { handleAvailable('vortxSemGarantia') }} className="close-button1">X</div>
                                                <VortxSemGarantia dados={dados} />
                                            </div>
                                            : null}
                                        {trusteeAvailable ?
                                            <div className={trusteeAvailable ? "pdf-container active" : "pdf-container"}>
                                                <div onClick={(e) => { handleAvailable('trusteeAvailable') }} className="close-button1">X</div>
                                                <TrusteeCondicoes dados={dados} />
                                            </div>
                                            : null}
                                    </div>

                                </div>
                                <div className="DocGenHeader">
                                    <div onClick={toggleModoCnab} className="DocGenButton">
                                        {modoCnabExpandido ? <div className="divIconAndText">Fechar CNAB <AiOutlineUp /></div> : <div className="divIconAndText">Gerar CNAB <AiOutlineDown /></div>}
                                    </div>
                                            <div onClick={() => handleCnab()} className="docButton1">
                                                {semGarantia ? 'Fechar Nota CNAB Singulare' : 'Modelo Singulare'}
                                            </div>
                                    {modoCnabExpandido && (
                                        <div className="buttonsContainer">

                                            <div onClick={() => window.alert('Em Breve!')} className="docButton1">
                                                {trusteeSemGarantia ? 'Fechar Nota Comercial' : 'Modelo Trustee'}
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        )} </div>
                </div>
                <div style={{ marginBottom: '20vh', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    
                    {sendingNote ? (
                        <div></div>
                        /*<button onClick={() => setSendingNote(false)} style={{ marginRight: '10px' }}>
                            {"Cancelar Envio"}
                        </button>*/
                    ) : (
                        <button onClick={() => setSendingNote(true)} style={{ marginRight: '10px' }}>
                            Solicitar Emissão de Nota Comercial
                        </button>
                    )}

                  {/*  <button
                        onClick={handleEscri}
                        style={{
                            textAlign: 'center',
                            color: '#ffffff',
                            border: 'none',
                            padding: '10px',
                            cursor: 'pointer',
                        }}
                        disabled={enviandoEscri}
                    >
                        {enviandoEscri ? (
                            <div>
                                <h1 style={{ fontSize: '14px' }}>Conectando com sistemas it4 {'\n'}</h1>
                                <ClipLoader color="#ffffff" loading={enviandoEscri} css={override} size={15} />
                            </div>
                        ) : (
                            'Integrar com sistema IT4FINANCE'
                        )}
                        </button>*/}
                        </div>

            </div>
        </body>
    )
}

export default DocGen;